import React from "react";
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import UserSession from "../data/UserSession";
var bugsnagClient;
var BugsnagErrorBoundary = React.Fragment;
function updateUser() {
    var session = UserSession.cacheOnly();
    if (session) {
        var user = session.user;
        bugsnagClient.user = {
            id: user.id,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
        };
    }
}
if (process.env.NODE_ENV === "production") {
    var apiKey = window.location.host === "portal.denkulturelleskolesekken.no"
        ? '1d5c16aa51951c92e10aec3cdacd8d07'
        : 'cd595e348a56fae675f7fc12ca23d8e1';
    bugsnagClient = bugsnag({ apiKey: apiKey, appVersion: process.env.APP_VERSION });
    bugsnagClient.use(bugsnagReact, React);
    BugsnagErrorBoundary = bugsnagClient.getPlugin('react');
    UserSession.subscribe(updateUser);
    updateUser();
}
export { BugsnagErrorBoundary, bugsnagClient };
