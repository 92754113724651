/**
 * Makes sure that only the newest input is passed to output.
 * Useful to prevent former ajax requests to overwrite newer ajax requests in search etc.
 * @param input
 * @param output
 * @return function
 */
export default function newest(input, output) {

    let revision = 0;
    let committedRevision = 0;

    return (...args) => {
        const currentRevision = ++revision;
        revision = currentRevision;

        return new Promise(async solve => {
            const response = await input(...args);

            if (currentRevision > committedRevision) {
                committedRevision = currentRevision;
                output(response, revision === currentRevision);
                solve(response);
            }
        });
    };
}