import React from "react";
import * as PropTypes from "prop-types";
import newest from "./utils/newest";
import {throttle} from "lodash-es";

export default class DataProvider extends React.PureComponent {
    static propTypes = {
        debounced: PropTypes.bool,
        query: PropTypes.any.isRequired,
        loadData: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        refresh: PropTypes.bool,
    };

    state = {data: null};
    updateData = newest(() => this.props.loadData(this.props.query), data => this.setState({data}));
    updateDataDebounced = throttle(this.updateData, 800, {leading: true, trailing: true});

    render() {
        return this.props.children(this.state.data);
    }

    componentDidMount(): void {
        this.updateData(this.props.query)
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {
        if (JSON.stringify(prevProps.query) !== JSON.stringify(this.props.query)) {
            this.updateDataDebounced();
        }

        if (prevProps.refresh !== this.props.refresh) {
            this.updateData(this.props.query);
        }
    }
}