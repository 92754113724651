import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import isIe from "@braintree/browser-detection/is-ie";

const RaisedTable = withStyles({
    root: {
        filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.24)) drop-shadow(0px 0px 1px rgba(0,0,0,0.12))',
        border: isIe() ? '1px solid #CCC' : null,
        background: '#FFF',
        borderCollapse: 'unset !important'
    }
})(Table);
const RaisedTableHead = withStyles({})(TableHead);
const RaisedTableBody = withStyles({})(TableBody);
const RaisedTableRow = withStyles({
    root: {
        height: '36px',
        '&:hover': {
            background: '#fafafa',
            filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.2)) drop-shadow(0px 2px 1px rgba(0,0,0,0.12)) drop-shadow(0px 0px 1px rgba(0,0,0,0.14))'
        },
        '&:focus': {
            background: '#fafafa',
            filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.2)) drop-shadow(0px 2px 1px rgba(0,0,0,0.12)) drop-shadow(0px 0px 1px rgba(0,0,0,0.14))'
        }
    },
    head: {
        background: '#fafafa',
        '&:hover': {
            filter: 'unset'
        }
    }
})(TableRow);
const RaisedTableCell = withStyles({})(TableCell);

export {RaisedTable, RaisedTableHead, RaisedTableBody, RaisedTableRow, RaisedTableCell};