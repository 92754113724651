import React from "react";

const styles = {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexFlow: 'column',
    alignItems: 'stretch',
    background: 'white'
};

export default React.memo(props => <div style={styles}>
    {props.children}
</div>);