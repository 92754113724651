import { useEffect, useState } from "react";
import { jsonPost } from "../../utils/JsonApi";
import CachedResourceWrapper, { NoCacheStrategy } from "../../utils/cache/CachedResourceWrapper";
export var Role;
(function (Role) {
    Role["staff"] = "ROLE_STAFF";
    Role["school"] = "ROLE_SCHOOL";
    Role["user"] = "ROLE_USER";
})(Role || (Role = {}));
var HeaderRepository = new CachedResourceWrapper(function () { return jsonPost('/api/web-authenticated/header/get_header'); }, new NoCacheStrategy());
export function useHeader() {
    var _a = useState(HeaderRepository.cacheOnly()), header = _a[0], setHeader = _a[1];
    useEffect(function () {
        HeaderRepository.networkFirst()
            .then(setHeader);
        return HeaderRepository.subscribe(setHeader);
    }, []);
    return header;
}
export default HeaderRepository;
