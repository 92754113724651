import React from "react";
import * as ReactDOM from "react-dom";
export function attachDivToBody() {
    var sNew = document.createElement("div");
    document.getElementsByTagName('body')[0].appendChild(sNew);
    return sNew;
}
export function renderModal(Component) {
    var sNew = document.createElement("div");
    document.getElementsByTagName('body')[0].appendChild(sNew);
    ReactDOM.render(React.createElement(Component, { onClose: function () {
            ReactDOM.unmountComponentAtNode(sNew);
            sNew.remove();
        } }), sNew);
}
