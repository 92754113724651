import React, {lazy, memo, Suspense} from "react";
import {useJsonResource} from "../../utils/JsonApi";

const CompleteProfileDialog = lazy(() => import("./CompleteProfileDialog"));

export default memo(() => {
    const [properties] = useJsonResource('/api/web-authenticated/widgets/complete_profile/get_complete_profile_data');

    if (!properties)
        return null;

    return <Suspense fallback={null}>
        <CompleteProfileDialog defaultProperties={properties}/>
    </Suspense>;
});