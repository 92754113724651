var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import resources from "../../i18n/resources";
var initialized = false;
export function getI18n(defaultOptions) {
    if (!initialized) {
        var options = __assign({ debug: process.env.NODE_ENV === "development", resources: resources, defaultNS: 'common' }, defaultOptions);
        if (!options.lng) {
            options.lng = getBrowserLanguage();
        }
        i18next
            .use(initReactI18next)
            .init(options);
        // i18next
        //     .on('missingKey', (lngs, namespace, key, res) => {
        //         console.error('missing key', {lngs, namespace, key});
        //     });
        i18next
            .on('languageChanged', function (lng) {
            setBrowserLanguage(lng);
        });
        initialized = true;
    }
    return i18next;
}
export function getBrowserLanguage() {
    return localStorage.getItem('browserLanguage') || 'nb';
}
export function setBrowserLanguage(language) {
    return localStorage.setItem('browserLanguage', language);
}
export function useTypedTranslation() {
    var t = useTranslation().t;
    return {
        // @ts-ignore
        t: function (domain, message, options) {
            if (message === undefined)
                return t(domain);
            return t(domain + ":" + message, options);
        }
    };
}
