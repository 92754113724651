import React, {memo, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {withPageConfiguration} from "../../../utils/PageConfiguration";
import {AppBar, FormControl, Icon, IconButton, InputLabel, Select, Toolbar, Typography} from "@material-ui/core";
import {Link, Redirect, Route, withRouter} from "react-router-dom";
import UserHeaderButton from "../../administrator_portal/header/UserHeaderButton";
import {HelpButton, Logo, MessageButton} from "../../performer_portal/header/PerformerPortalHeader";
import {useHeader} from "../../../data/repositories/HeaderRepository";
import {groupBy} from "lodash-es";
import {useTranslation} from "react-i18next";
import { useTypedTranslation } from "../../../utils/i18n";
import { styleWCAG } from "../../../utils/WCAGThemeUtils";
import clsx from "clsx";

const SchoolPortalDrawer = React.lazy(() => import("./SchoolPortalDrawer"));
export default () => <Route path={['/r/school/:schoolId', '/']} component={Component}/>;

const useStyles = makeStyles(theme => ({
    appBar: {
        borderBottom: '1px solid #CCC',
        background: '#e8eafe'
    },
    toolbar: {
        height: '56px',
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
        color: '#0033da'
    },
    title: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginRight: '20px',
        maxWidth: '200px',
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        minWidth: '350px',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        justifyContent: 'flex-end',

        [theme.breakpoints.down('xs')]: {
            flex: 'unset',
        }
    },
}), {name: 'SchoolPortalHeader'});

const Component = withPageConfiguration(memo(({title}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { t } = useTypedTranslation();
    const WCAGClasses = styleWCAG();


    return <AppBar position="static" elevation={0} className={classes.appBar} role={'navigation'}>
        <React.Suspense fallback={null}>
            <SchoolPortalDrawer
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />


            <Toolbar variant={"dense"} className={classes.toolbar}>
                <div className={classes.left}>
                    <IconButton color={'inherit'} onClick={() => setOpen(true)}>
                        <Icon aria-label={t('common', 'menu_icon')}>menu</Icon>
                    </IconButton>

                    <div style={{width: '15px'}}/>

                    <Typography color={"inherit"} variant="h1" className={clsx(classes.title, WCAGClasses.h6)}>
                        {title}
                    </Typography>

                    <SchoolSelector/>
                </div>

                <Logo
                    component={Link}
                    to={'/r/school'}
                />

                <div className={classes.right}>
                    <HelpButton portal={'school'}/>
                    <MessageButton portal={'school'}/>
                    <UserHeaderButton portal={'school'}/>
                </div>
            </Toolbar>
        </React.Suspense>
    </AppBar>;
}));

function generateLink(pathname, id) {
    if (pathname.startsWith('/r/school/')) {
        const parts = pathname.split('/');
        if (parseInt(parts[3]) || parts[3] === '_') {
            parts[3] = id;
            return parts.join('/');
        }
    }


    return '/r/school/' + id;
}

const SchoolSelector = withRouter(memo((props) => {
    const {t} = useTranslation('school_portal');
    const {match: {params: {schoolId}}} = props;
    const [modal, setModal] = useState(null);
    const header = useHeader();

    const inputLabelRef = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(inputLabelRef.current.offsetWidth);
    }, [inputLabelRef.current?.offsetWidth]);

    useEffect(() => {
        setModal(null);
    }, [schoolId])

    const items = [];

    if (header) {
        const schoolsGroupedByBranchOffice = groupBy(header.schools, s => s.branchOffice?.name || '');

        Object.keys(schoolsGroupedByBranchOffice).forEach(branchOfficeName => {
            const schools = schoolsGroupedByBranchOffice[branchOfficeName];
            const listItems = schools.map(s => <option key={s.id} value={s.id}>{s.name}</option>);

            if (branchOfficeName) {
                items.push(
                    <optgroup key={branchOfficeName} label={branchOfficeName}>
                        {listItems}
                    </optgroup>
                );
            } else {
                items.push(...listItems);
            }
        });

        // header.schools.forEach((school, index) => {
        //     const prevSchool = header.schools[index - 1];
        //
        //     if (prevSchool !== null && school.branchOffice && (!prevSchool.branchOffice || prevSchool.branchOffice.id !== school.branchOffice.id)) {
        //         items.push(<ListSubheader
        //             key={'bo' + school.branchOffice.id}>{school.branchOffice.name}</ListSubheader>);
        //     }
        //
        //     items.push(<MenuItem
        //         key={school.id}
        //         value={school.id}
        //         component={Link}
        //         to={generateLink(props.location.pathname, school.id)}
        //     >
        //         {school.name}
        //     </MenuItem>);
        // });
    }

    return <FormControl variant={'outlined'}>
        <InputLabel shrink ref={inputLabelRef} htmlFor={'school-selector'}>{t('select_school')}</InputLabel>
        <Select
            native
            notched
            value={schoolId || header?.defaultSchoolId || ''}
            labelWidth={labelWidth}
            inputProps={{
                id: 'school-selector',
            }}
            onChange={(e) => setModal(
                <Redirect push to={generateLink(props.location.pathname + props.location.search, e.target.value)}/>
            )}
        >
            {items}
        </Select>
        {modal}
    </FormControl>;
}));