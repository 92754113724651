import React, {memo, useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {
    RaisedTable,
    RaisedTableBody,
    RaisedTableCell,
    RaisedTableHead,
    RaisedTableRow
} from "../../../components/tables/RaisedTable";
import MobileBreakpointFunction from "../../../components/utils/MobileBreakpointFunction";
import ApplicationEntity from "../../../data/entity/ApplicationEntity";
import {formatDateTimeMed} from "../../../common/DateTimeFormats";
import {formatYearLevel} from "../../../common/YearLevelUtils";
import {Button, Icon, IconButton, Paper, TableSortLabel, Tooltip, Typography} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {jsonPost} from "../../../utils/JsonApi";
import LoadingModal from "../../../components/loaders/LoadingModal";
import {useTranslation} from "react-i18next";
import { getStatusAplicationCouncilGroupEvaluation, getStatusAplicationCouncilGroupExternalEvaluator } from "../../../utils/StatusUtils";
import { getCulturalExpressions } from "../../../utils/CulturalExpressionUtil";

const ListPropertyCard = memo(({children, ...props}) => <Paper elevation={2} style={{
    display: 'block',
    textDecoration: 'none',
    padding: '15px',
    marginBottom: '10px'
}} {...props}>
    {children}
</Paper>);
const ListProperty = memo(props => <div style={{display: 'flex', alignItems: 'center', minHeight: '25px'}}>
    <Typography style={{fontSize: '12px', color: 'rgba(0,0,0,0.5)', width: '130px'}}>
        {props.label}
    </Typography>
    <Typography style={{fontSize: '13px', flex: '1'}}>
        {props.value}
    </Typography>
    {props.action}
</div>);

export {ListProperty, ListPropertyCard};

function getLink(application: ApplicationEntity, isEvaluator = false) {
    let path = isEvaluator ? ('council-group/' + application.councilGroup.id + '/evaluator') : 'editor';
    return '/r/performer/application/' + application.id + '/' + path;
}

const useStyles = makeStyles({
    textStatus: {
        maxWidth: '180px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
})

export default memo(props => {
    const {t, i18n} = useTranslation(['application_fields', 'common', 'application_status_performer', 'application_under_consider_fields']);
    const {isEvaluator, sort, onSort, applications} = props;

    const handleSortChange = (keySort: [[string, string]]): void => {
        if (sort[0][0] === keySort) {
            onSort([[keySort, sort[0][1] === "ASC" ? "DESC" : "ASC"]]);
        } else {
            onSort([[keySort, "ASC"]]);
        }
    }

    return (
        <MobileBreakpointFunction>
            {(isMobile) => isMobile
                  ?  <div>
                        {applications.map((application: ApplicationEntity) => <ListPropertyCard
                    component={Link}
                    to={getLink(application, isEvaluator)}
                >
                    <ListProperty
                        label={t("application_under_consider_fields:reference_id")}
                        value={application.referenceNumber}
                    />
                    <ListProperty
                        label={t("application_under_consider_fields:name")}
                        value={application.name}
                    />
                    <ListProperty
                        label={t("application_under_consider_fields:cultural_expression")}
                        value={getCulturalExpressions(application, i18n.language)}
                    />
                    <ListProperty
                        label={t("application_under_consider_fields:target_group")}
                        value={formatYearLevel(application.yearLevelMinimum, application.yearLevelMaximum, application.yearLevelDescription, t)}
                    />
                    <ListProperty
                        label={t("application_under_consider_fields:my_evaluation_status")}
                        value={getStatusAplicationCouncilGroupEvaluation(getApplicationPersonalEvaluation(application, 'status'), t)}
                    />
                    <ListProperty
                        label={t("application_under_consider_fields:my_evaluation_text")}
                        value={getApplicationPersonalEvaluation(application, 'text')}
                    />
                    <ListProperty
                        label={t("application_under_consider_fields:last_seen")}
                        value={formatDateTimeMed(application.lastSeenTime.length > 0 ? application.lastSeenTime[0] : '')}
                    />
                    <ListProperty 
                        label= {t(
                            "application_under_consider_fields:external_evaluator_group_evaluation_status"
                        )}
                        value= {getStatusAplicationCouncilGroupExternalEvaluator(application.externalEvaluatorGroupEvaluationStatus, t)}
                    />
                </ListPropertyCard>)}
                    </div>
                    : <div>
                        <RaisedTable>
                            <RaisedTableHead>
                                <RaisedTableRow>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "reference_id"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("reference_id")}
                                        >
                                            {t("application_under_consider_fields:reference_id")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "name"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("name")}
                                        >
                                            {t("application_under_consider_fields:name")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "cultural_expressions"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("cultural_expressions")}
                                        >
                                            {t("application_under_consider_fields:cultural_expression")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "target_group"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("target_group")}
                                        >
                                            {t("application_under_consider_fields:target_group")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "my_evaluation_status"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("my_evaluation_status")}
                                        >
                                            {t("application_under_consider_fields:my_evaluation_status")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "my_evaluation_text"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("my_evaluation_text")}
                                        >
                                            {t("application_under_consider_fields:my_evaluation_text")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "last_seen_time"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() => handleSortChange("last_seen_time")}
                                        >
                                            {t("application_under_consider_fields:last_seen")}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell>
                                        <TableSortLabel
                                            active={sort[0][0] === "external_evaluator_group_evaluation_status"}
                                            direction={sort[0][1].toLowerCase()}
                                            onClick={() =>
                                                handleSortChange("external_evaluator_group_evaluation_status")
                                            }
                                        >
                                            {t(
                                                "application_under_consider_fields:external_evaluator_group_evaluation_status"
                                            )}
                                        </TableSortLabel>
                                    </RaisedTableCell>
                                    <RaisedTableCell component="td" style={{width: '60px'}}/>
                                </RaisedTableRow>
                            </RaisedTableHead>
                            <RaisedTableBody>
                                {applications.map((application: ApplicationEntity) => {
                                    return (
                                        <ApplicationTableRow
                                            key={application.id + "_" + application.councilGroup.id}
                                            application={application}
                                            isEvaluator={isEvaluator}
                                        />
                                    );
                                })}
                            </RaisedTableBody>
                        </RaisedTable>
                    </div>
                }
        </MobileBreakpointFunction>
    );
});


const SmallIconButton = withStyles({
    root: {
        padding: '3px',
    }
})(IconButton);

const ApplicationTableRow = memo(props => {
    const {t, i18n} = useTranslation();
    const [modal, setModal] = useState(null);
    const [hovering, setHovering] = useState(false);
    const application: ApplicationEntity = props.application;
    const isEvaluator = props.isEvaluator;
    const classes = useStyles();
    
    const lastSeenTime: string = application.lastSeenTime.length > 0 ? application.lastSeenTime[0] : '';

    return <>
        <RaisedTableRow
            component={Link}
            to={getLink(application, isEvaluator)}
            onMouseOver={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <RaisedTableCell>
                {application.referenceNumber}
            </RaisedTableCell>
            <RaisedTableCell>
                {application.name}
            </RaisedTableCell>
            <RaisedTableCell>
                {getCulturalExpressions(application, i18n.language)}
            </RaisedTableCell>
            <RaisedTableCell>
                {formatYearLevel(application.yearLevelMinimum, application.yearLevelMaximum, application.yearLevelDescription, t)}  
            </RaisedTableCell>
            <RaisedTableCell>
                <Tooltip title={getApplicationPersonalEvaluation(application, 'text')}>
                    <div>{getStatusAplicationCouncilGroupEvaluation(getApplicationPersonalEvaluation(application, 'status'), t)}</div>
                </Tooltip>
            </RaisedTableCell>
            <RaisedTableCell>
                <Tooltip title={getApplicationPersonalEvaluation(application, 'text')}>
                    <div className={classes.textStatus}>{getApplicationPersonalEvaluation(application, 'text')}</div>
                </Tooltip> 
            </RaisedTableCell>
            <RaisedTableCell>
                {formatDateTimeMed(lastSeenTime)}
            </RaisedTableCell>
            <RaisedTableCell style={{padding: '0 20px 0 0', height: '42px'}}>
                <Tooltip title={<div dangerouslySetInnerHTML={{ __html: application.externalEvaluatorGroupEvaluationText}} />}>
                    <div>{getStatusAplicationCouncilGroupExternalEvaluator(application.externalEvaluatorGroupEvaluationStatus, t)}</div> 
                </Tooltip>
            </RaisedTableCell>
            <RaisedTableCell>
                {hovering
                        ? <div>
                            {application.hasEditorAccess
                                ? <Tooltip placement={"top"} title={t('common:edit')}>
                                    <SmallIconButton
                                        component={Link}
                                        to={'/r/performer/application/' + application.id + '/editor'}
                                    >
                                        <Icon>edit</Icon>
                                    </SmallIconButton>
                                </Tooltip>
                                : null}
                            {application.hasCloneAccess
                                ? <Tooltip placement={"top"} title={t('common:clone')}>
                                    <SmallIconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            setModal(<CloneApplicationModal applicationId={application.id}/>);
                                        }}
                                    >
                                        <Icon>file_copy</Icon>
                                    </SmallIconButton>
                                </Tooltip>
                                : null}
                        </div>
                        : null}
            </RaisedTableCell>
        </RaisedTableRow>
        {modal}
    </>;
});

const CloneApplicationModal = memo(props => {
    const [newApplicationId, setNewApplicationId] = useState(null);

    useEffect(() => {
        jsonPost('/api/web-authenticated/applications/clone_application', {applicationId: props.applicationId})
            .then(s => setNewApplicationId(s.id));
    }, []);

    if (!newApplicationId)
        return <LoadingModal/>;

    return <Redirect
        push
        to={'/r/performer/application/' + newApplicationId + '/editor'}
    />;
});

export {CloneApplicationModal};



function getApplicationPersonalEvaluation(application: ApplicationEntity, key: string): string {
    return application.personalEvaluations
        .map((a) => {
            if (key === 'text') 
                return a.evaluation
            else 
                return a.status
        })
        .join(',')
}