import React, { memo, useCallback } from "react";
import { Checkbox, CircularProgress, Icon, IconButton, TableCell } from "@material-ui/core";
export function ProjectSelectorHierarchy(props) {
    return React.createElement("div", { style: { width: '700px', height: '400px', overflowY: 'auto' } }, props.children);
}
export function ProjectSelectorHierarchyLoading() {
    return React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px' } },
        React.createElement(CircularProgress, { size: 35 }));
}
export var ProjectSelectorHierarchyCell = memo(function (props) {
    var handleExpand = useCallback(function () { return props.onExpand(!props.expanded); }, [props.onExpand, props.expanded]);
    var handleChange = useCallback(function (e, check) { return props.onCheck(check); }, [props.onCheck]);
    var indentation = props.indentation, expanded = props.expanded, indeterminate = props.indeterminate, checked = props.checked, children = props.children;
    return React.createElement(TableCell, null,
        React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
            React.createElement("div", { style: { width: (indentation * 24) + 'px' } }),
            expanded !== undefined ? React.createElement("div", { style: { width: '30px' } }, typeof expanded === 'boolean'
                ? React.createElement(IconButton, { color: "primary", style: { padding: '0' }, onClick: handleExpand },
                    React.createElement(Icon, { style: { transform: expanded ? 'rotate(90deg)' : undefined } }, "arrow_right"))
                : null) : null,
            checked !== undefined ? React.createElement("div", { style: { width: '30px' } }, typeof checked === 'boolean'
                ? React.createElement(Checkbox, { indeterminate: indeterminate, checked: checked, onChange: handleChange, style: { marginRight: '8px', padding: '0' } })
                : null) : null,
            children));
});
