import React, { memo } from "react";
import { RaisedTable, RaisedTableBody, RaisedTableCell, RaisedTableHead, RaisedTableRow } from "../../../components/tables/RaisedTable";
import { useTranslation } from "react-i18next";
export default memo(function (_a) {
    var councilGroups = _a.councilGroups;
    var t = useTranslation('council_group').t;
    return React.createElement("div", null,
        React.createElement(RaisedTable, null,
            React.createElement(RaisedTableHead, null,
                React.createElement(RaisedTableRow, null,
                    React.createElement(RaisedTableCell, null, t('branch_office')),
                    React.createElement(RaisedTableCell, null, t('evaluator_group')),
                    React.createElement(RaisedTableCell, null, t('number_of_proposals')),
                    React.createElement(RaisedTableCell, null, t('recommend')),
                    React.createElement(RaisedTableCell, null, t('not_recommend')),
                    React.createElement(RaisedTableCell, null, t('not_evaluated')))),
            React.createElement(RaisedTableBody, null, councilGroups.map(function (councilGroup) { return React.createElement(CouncilGroupTableRow, { key: councilGroup.id, councilGroup: councilGroup }); }))));
});
var CouncilGroupTableRow = memo(function (_a) {
    var councilGroup = _a.councilGroup;
    return React.createElement(React.Fragment, null,
        React.createElement(RaisedTableRow, { style: { cursor: 'pointer' }, onClick: function () {
                window.location.href = "/r/performer/council-group/" + councilGroup.id + "/application-under-consideration";
            } },
            React.createElement(RaisedTableCell, null, councilGroup.branchOfficeName),
            React.createElement(RaisedTableCell, null, councilGroup.name),
            React.createElement(RaisedTableCell, null, councilGroup.totalApplications),
            React.createElement(RaisedTableCell, null, councilGroup.totalApplicationsRecommend),
            React.createElement(RaisedTableCell, null, councilGroup.totalApplicationsNotRecommended),
            React.createElement(RaisedTableCell, null, councilGroup.totalApplicationsNotEvaluated)));
});
