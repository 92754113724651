var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Button } from '@material-ui/core';
import IconText from "../../IconText";
import { useTranslation } from "react-i18next";
export default React.memo(function (p) {
    var t = useTranslation().t;
    return React.createElement(Button, __assign({}, p),
        React.createElement(IconText, { icon: 'close', text: t('clear_filters') }));
});
