var RoleTypes = {
    SYSADMIN: 'N',
    BRANCH_OFFICE: 'B',
    SCHOOL: 'S',
    SCHOOL_EMPLOYEE: 'T',
    MUNICIPALITY_CONTACT: 'M',
    PERFORMER: 'P',
    CULTURAL_INSTITUTION: 'C',
    WORDPRESS: 'W',
    COUNCIL_MEMBER: 'F',
    SIGN_PROTOCOL: 'L'
    //OLD_COUNCIL_MEMBER: 'OF'
};
export { RoleTypes as default };
