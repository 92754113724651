export function isApp() {
    return isAndroid() || isIOS();
}
export function isAndroid() {
    return document.cookie.includes('PWA=ANDROID');
}
export function isIOS() {
    return document.cookie.includes('PWA=IOS');
}
export function getPwa() {
    if (isIOS()) {
        return 'ios';
    }
    if (isAndroid()) {
        return 'android';
    }
    return null;
}
