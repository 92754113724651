import { STYLES } from './../data/constants';
import _ from 'lodash';
import { getI18n } from './i18n';
import { makeStyles } from '@material-ui/styles';
import { grey, red } from '@material-ui/core/colors';
var i18n = getI18n({});
var WCAGThemeOptions = {
    palette: {
        contrastThreshold: 4.5,
        error: {
            main: red[700]
        }
    },
    props: {
        MuiIcon: {
            'aria-label': i18n.t('icon'),
            'aria-hidden': false
        },
        MuiSvgIcon: {
            'aria-label': i18n.t('icon'),
            'aria-hidden': false
        }
    },
    typography: {
        h1: {
            lineHeight: STYLES.LINE_HEIGHT.DEFAULT
        },
        h2: {
            lineHeight: STYLES.LINE_HEIGHT.DEFAULT
        },
        h3: {
            lineHeight: STYLES.LINE_HEIGHT.DEFAULT
        },
        h4: {
            lineHeight: STYLES.LINE_HEIGHT.DEFAULT
        },
        h5: {
            lineHeight: STYLES.LINE_HEIGHT.DEFAULT
        },
        body2: {
            lineHeight: STYLES.LINE_HEIGHT.DEFAULT
        }
    },
};
var WCAGClasses = {
    page_title: {
        fontSize: STYLES.FONT_SIZE.H5,
        fontWeight: STYLES.FONT_WEIGHT[500],
        letterSpacing: STYLES.LETTER_SPACING.LETTER_SPACING_1
    },
    title_info: {
        fontSize: STYLES.FONT_SIZE.H5,
        fontWeight: STYLES.FONT_WEIGHT[400],
        letterSpacing: STYLES.LETTER_SPACING.LETTER_SPACING_3
    },
    title: {
        fontSize: STYLES.FONT_SIZE.H4,
        fontWeight: STYLES.FONT_WEIGHT[400],
        letterSpacing: STYLES.LETTER_SPACING.LETTER_SPACING_2
    },
    subtitle: {
        fontSize: STYLES.FONT_SIZE.H5,
        fontWeight: STYLES.FONT_WEIGHT[500],
        letterSpacing: STYLES.LETTER_SPACING.LETTER_SPACING_1
    },
    h3: {
        fontSize: STYLES.FONT_SIZE.H3,
        fontWeight: STYLES.FONT_WEIGHT[400],
        letterSpacing: STYLES.LETTER_SPACING.LETTER_SPACING_3,
        lineHeight: STYLES.LINE_HEIGHT.DEFAULT
    },
    h6: {
        fontSize: STYLES.FONT_SIZE.H6,
        fontWeight: STYLES.FONT_WEIGHT[500],
        letterSpacing: STYLES.LETTER_SPACING.LETTER_SPACING_1,
        lineHeight: STYLES.LINE_HEIGHT.H6
    },
    subtitle1: {
        fontSize: STYLES.FONT_SIZE.SUBTITLE_1,
        fontWeight: STYLES.FONT_WEIGHT[400],
        letterSpacing: STYLES.LETTER_SPACING.SUBTITLE_1,
        lineHeight: STYLES.LINE_HEIGHT.SUBTITLE_1
    },
    subtitle2: {
        fontSize: STYLES.FONT_SIZE.SUBTITLE_2,
        fontWeight: STYLES.FONT_WEIGHT[500],
        letterSpacing: STYLES.LETTER_SPACING.SUBTITLE_2,
        lineHeight: STYLES.LINE_HEIGHT.SUBTITLE_2
    },
    hiddenLabel: {
        width: 0,
        height: 0,
        display: STYLES.DISPLAY.INLINE_BLOCK,
        overflow: STYLES.OVERFLOW.HIDDEN
    },
    textContrast: {
        color: grey[700]
    },
    captionContrast: {
        color: grey[800]
    }
};
var styleWCAG = makeStyles(WCAGClasses);
var applyWCAGThemeOptions = function (themeOptions) { return _.merge(WCAGThemeOptions, themeOptions); };
export { WCAGThemeOptions, applyWCAGThemeOptions, styleWCAG, WCAGClasses };
