import '@babel/polyfill';
import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import {attachDivToBody} from "../utils/AnchorDiv";
import {BugsnagErrorBoundary} from "../utils/BugsnagErrorBoundary";
import {loadStyles} from "../utils/styles";
import AdministratorPortalThemeProvider from "../utils/AdministratorPortalThemeProvider";
import App from "../controllers/App";
import LoadingScreen from "../components/loaders/LoadingScreen";
import {LanguageProvider} from "../controllers/performer_portal/PerformerPortal";
import createClient from "../data/client";
import {ApolloProvider} from "react-apollo";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
// import {signOut} from "../data/UserSession";

loadStyles();

const client = createClient();

window.attachApp = () => {
    ReactDOM.render(
        <BugsnagErrorBoundary>
            <ApolloProvider client={client}>
                <AdministratorPortalThemeProvider>
                    <App/>
                </AdministratorPortalThemeProvider>
            </ApolloProvider>
        </BugsnagErrorBoundary>,
        attachDivToBody()
    );
};

window.attachWordPressAuth = props => {
    const WordPressAuth = lazy(() => import('../controllers/WordPressAuth'));

    ReactDOM.render(
        <Suspense fallback={<LoadingScreen/>}>
            <BugsnagErrorBoundary>
                <AdministratorPortalThemeProvider>
                    <LanguageProvider>
                        <WordPressAuth {...props}/>
                    </LanguageProvider>
                </AdministratorPortalThemeProvider>
            </BugsnagErrorBoundary>
        </Suspense>,
        attachDivToBody()
    );
};

window.attachSamlRegisterModal = props => {
    const TokenizedRegistrationDialog = lazy(() => import('../controllers/authentication/TokenizedRegistrationDialog'));

    ReactDOM.render(
        <Suspense fallback={<LoadingScreen/>}>
            <BugsnagErrorBoundary>
                <AdministratorPortalThemeProvider>
                    <LanguageProvider>
                        <TokenizedRegistrationDialog defaultProperties={props}/>
                    </LanguageProvider>
                </AdministratorPortalThemeProvider>
            </BugsnagErrorBoundary>
        </Suspense>,
        attachDivToBody()
    );
};

window.renderSamlAuthenticationFailedModal = props => {
    ReactDOM.render(
        <Dialog open>
            <DialogTitle>Access denied</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your user does not have access to this resource.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} href={'/auth/saml/log_out'}>
                    SIGN OUT
                </Button>
            </DialogActions>
        </Dialog>,
        attachDivToBody()
    )
}