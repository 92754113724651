import React, {useRef, useState} from "react";
import {Avatar, ClickAwayListener, Icon, IconButton} from '@material-ui/core';
import {useUserSession} from "../../../data/UserSession";

const UserHeaderPopper = React.lazy(() => import( "./UserHeaderPopper"));

const UserHeaderButton = React.memo(({portal}) => {
    const [open, setOpen] = useState(false);
    const ref = useRef();

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div ref={ref}>
            <UserAvatar
                onClick={() => setOpen(!open)}
            />
            <React.Suspense fallback={null}>
                <UserHeaderPopper
                    open={open}
                    anchorEl={ref.current}
                    portal={portal}
                    onClose={() => setOpen(false)}
                />
            </React.Suspense>
        </div>
    </ClickAwayListener>;
});

export default UserHeaderButton;

function UserAvatar(props) {
    const userSession = useUserSession();
    const imageUrl = userSession?.user?.imageUrl;

    return <IconButton color={"inherit"} onClick={props.onClick} style={imageUrl ? {padding: '6px'} : null} aria-label={'Aktiv bruker meny'}>
        {imageUrl
            ? <Avatar
                src={imageUrl}
                alt={'Avatar'}
                style={{width: '32px', height: '32px'}}
            />
            : <Icon>person</Icon>}
    </IconButton>
}