import React from "react";
import * as PropTypes from "prop-types";
import {AppBar, Dialog, Icon, IconButton, Toolbar, Typography} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";

export default class MobileDialog extends React.PureComponent {

    static propTypes = {
        title: PropTypes.node.isRequired,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
    };

    _isMounted: boolean = false;
    saving: boolean = false;

    render(): React.ReactNode {
        // noinspection JSUnusedLocalSymbols
        const {title, onClose, onSave, children, DialogContentProps, saving, ...props} = this.props;

        return <Dialog open fullScreen onClose={onClose} {...props}>
            <div style={{width: '100%', height: '100%', display: 'flex', flexFlow: 'column', alignItems: 'stretch'}}>
                <div style={{flexShrink: '0'}}>
                    <AppBar position={"static"}>
                        <Toolbar color={'primary'}>
                            <IconButton color={'inherit'} onClick={onClose}>
                                <Icon>keyboard_arrow_left</Icon>
                            </IconButton>

                            <Typography variant={"h6"} color={"inherit"}>
                                {title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <div style={{position: 'relative', flex: '1', overflowY: 'auto'}}>
                    {children}
                </div>

                {onSave ? <Fab style={{
                            position: 'absolute',
                            bottom: '24px',
                            right: '24px',
                            background: green[500]
                        }} color={'primary'} onClick={onSave}>
                            {saving
                                ? <CircularProgress style={{width: '30px', height: '30px', color: '#FFF'}}/>
                                : <Icon>save</Icon>}
                        </Fab> : null}
            </div>
        </Dialog>;
    }
}