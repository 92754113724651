var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import ClickableSelectField from "../../fields/ClickableSelectField";
import SchoolGroupSelectorModal from "../../../widgets/school_groups/SchoolGroupSelectorModal";
import SchoolSelectorModal from "../../../widgets/schools/SchoolSelectorModal";
import TourSelectorModal from "../../../widgets/tours/TourSelectorModal";
import { useTranslation } from "react-i18next";
import CulturalInstitutionSelectorModal, { structureElementPrefix } from "../../../widgets/cultural_institution/CulturalInstitutionSelectorModal";
import BranchOfficeSelectorDialog from "../../../widgets/branch_office/BranchOfficeSelectorDialog";
import UserAdministratorSelectorModal from "../../../widgets/users/UserAdministratorSelectorModal";
var filterKeys = {
    SCHOOL_GROUP: 'schoolGroupIds',
    SCHOOL: 'schoolIds',
    TOUR: 'tourIds',
    CULTURAL_INSTITUTION: 'culturalInstitutionIds',
    BRANCH_OFFICE: 'branchOfficeIds',
    MUNICIPALITY: 'municipalityIds',
    TOUR_SUBSCRIBER: 'messageSubscriberIds'
};
export function createSelectorModalFilter(SelectorModal, key, label, includeChildren) {
    if (includeChildren === void 0) { includeChildren = false; }
    var component = React.memo(function (_a) {
        var value = _a.value, onChange = _a.onChange;
        var t = useTranslation('fields').t;
        var _b = useState(), modal = _b[0], setModal = _b[1];
        var _c = typeof label === 'string'
            ? [label, label + 's']
            : label, singular = _c[0], plural = _c[1];
        if (key === filterKeys.CULTURAL_INSTITUTION && value)
            value = value.filter(function (v) { return v.toString().startsWith(structureElementPrefix.CULTURAL_INSTITUTION); });
        return React.createElement(React.Fragment, null,
            React.createElement(ClickableSelectField, { label: t(plural), value: value && value.length
                    ? value.length + ' ' + t(value.length > 1 ? plural : singular)
                    : '', onClick: function () {
                    setModal(React.createElement(SelectorModal, { requireReadable: true, defaultValue: value ? value : undefined, onClose: function () { return setModal(null); }, onSelect: function (newValue) {
                            onChange(newValue);
                            setModal(null);
                        }, includeChildren: includeChildren }));
                } }),
            modal);
    });
    component.key = key;
    return component;
}
export var SchoolGroupFilter = createSelectorModalFilter(SchoolGroupSelectorModal, filterKeys.SCHOOL_GROUP, "school_group");
export var SchoolFilter = createSelectorModalFilter(SchoolSelectorModal, filterKeys.SCHOOL, "filtering_school");
export var TourFilter = createSelectorModalFilter(TourSelectorModal, filterKeys.TOUR, "tour");
export var CulturalInstitutionFilter = createSelectorModalFilter(CulturalInstitutionSelectorModal, filterKeys.CULTURAL_INSTITUTION, "cultural_institution");
export var ReadableBranchOfficeFilter = createSelectorModalFilter(BranchOfficeSelectorDialog, filterKeys.BRANCH_OFFICE, "branch_office");
// For DKS selection modal with child
export var IncludeChildrenReadableBranchOfficeFilter = createSelectorModalFilter(BranchOfficeSelectorDialog, filterKeys.BRANCH_OFFICE, "branch_office", true);
export var MunicipalityFilter = createSelectorModalFilter(function (props) { return React.createElement(BranchOfficeSelectorDialog, __assign({ label: useTranslation('fields').t('choose_municipalities') }, props)); }, filterKeys.MUNICIPALITY, ["municipality", "municipalities"]);
export var TourSubscribersFilter = createSelectorModalFilter(UserAdministratorSelectorModal, filterKeys.TOUR_SUBSCRIBER, ['tour_subscribers', 'tour_subscribers']);
