var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { STYLES } from "../data/constants";
import { applyWCAGThemeOptions } from "./WCAGThemeUtils";
var switchColorOptions = {
    '&$checked': {
        color: green[500],
    },
    '&$checked + $track': {
        backgroundColor: green[500],
    },
};
var options = {
    palette: {
        primary: {
            main: '#0033da',
        },
        secondary: {
            main: '#ca0a2c',
        },
        background: {
            default: "#FFF"
        }
    },
    spacing: 5,
    typography: {
        // useNextVariants: true,
        caption: {
            color: '#686868',
            lineHeight: '1'
        },
        overline: {
            color: 'rgba(0,0,0,0.6)'
        },
    },
    overrides: {
        MuiTable: {
            root: {
                width: '100%',
            }
        },
        MuiTableRow: {
            root: {
                textDecoration: 'none',
                height: '30px'
            },
            head: {
                background: '#fafafa',
            }
        },
        MuiTableCell: {
            root: {
                padding: '3px 6px',
                '&:first-child': {
                    paddingLeft: '24px'
                }
            },
            head: {
                color: '#1a1a1a',
                fontSize: '14px'
            }
        },
        MuiTabs: {
            root: {
                borderBottom: '1px solid #ddd',
                minHeight: '32px',
            }
        },
        MuiTab: {
            root: {
                minWidth: '0 !important',
                minHeight: '32px',
                marginRight: '20px',
                padding: '4px !important',
            },
            wrapper: {
                textTransform: 'none'
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '9.5px 14px'
            },
            multiline: {
                padding: '9.5px 14px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 12px) scale(1)'
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '1rem',
            }
        },
        MuiSwitch: {
            colorPrimary: switchColorOptions,
            colorSecondary: switchColorOptions
        }
    },
};
var optionsV2 = __assign(__assign({}, options), { typography: __assign(__assign({}, options.typography), { h2: {
            fontSize: STYLES.FONT_SIZE.H2
        }, h3: {
            fontSize: STYLES.FONT_SIZE.H3
        }, h4: {
            fontSize: STYLES.FONT_SIZE.H4
        }, h5: {
            fontSize: STYLES.FONT_SIZE.H5
        } }) });
var selectedOptions = applyWCAGThemeOptions(optionsV2);
export { selectedOptions as options };
var theme = createMuiTheme(selectedOptions);
var AdministratorPortalThemeProvider = function (_a) {
    var children = _a.children;
    return React.createElement(MuiThemeProvider, { theme: theme },
        React.createElement(CssBaseline, null),
        children);
};
export default AdministratorPortalThemeProvider;
