var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { memo, useCallback, useState } from "react";
import ProjectSelectorDialog from "./ProjectSelectorDialog";
import { ProjectSelectorTab, ProjectSelectorTabs } from "./ProjectSelectorTabs";
import { ProjectSelectorHierarchy, ProjectSelectorHierarchyCell, ProjectSelectorHierarchyLoading } from "./ProjectSelectorHierarchy";
import { Button, DialogActions, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { difference, find, intersection, pullAll, uniq } from "lodash-es";
import IconText from "../IconText";
import { withTranslation } from "react-i18next";
var HierarchySelectorDialog = /** @class */ (function (_super) {
    __extends(HierarchySelectorDialog, _super);
    function HierarchySelectorDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            search: '',
            page: null,
            value: _this.props.defaultValue || []
        };
        _this.handleSelect = function () { return _this.props.onSelect(_this.state.value, _this.state.page); };
        _this.handleSearch = function (search) { return _this.setState({ search: search }); };
        _this.handlePageChange = function (e, page) { return _this.setState({ page: page }); };
        _this.handleChange = function (value) { return _this.setState({ value: value }); };
        _this.searchFilter = function (children) {
            var search = _this.state.search.toLocaleLowerCase();
            if (!search.trim().length)
                return children;
            return children
                .map(function (child) {
                if (child.children) {
                    return __assign(__assign({}, child), { 
                        //get children for a parent node
                        children: _this.buildChildren(child, function () { return !!_this.props.isFallbackOnParentNodeEnabled && child.label.toLocaleLowerCase().includes(search); }) });
                }
                else {
                    return child;
                }
            })
                //check all nodes (including leaf node)
                .filter(function (child) { var _a; return ((_a = child.children) === null || _a === void 0 ? void 0 : _a.length) > 0 || child.label.toLocaleLowerCase().includes(search); });
        };
        _this.buildChildren = function (node, checkConditionToFallback) {
            if (!node.children)
                return [];
            var validChildren = _this.searchFilter(node.children);
            //fallback: no children and parent node hit search condition.
            if (validChildren.length === 0 && checkConditionToFallback())
                return node.children;
            return validChildren;
        };
        return _this;
    }
    HierarchySelectorDialog.prototype.render = function () {
        var _this = this;
        var _a = this.props, t = _a.t, label = _a.label, structures = _a.structures, onClose = _a.onClose;
        var _b = this.state, search = _b.search, value = _b.value;
        var structure = find(structures, function (s) { return s.key === _this.state.page; }) || structures[0];
        var page = structure.key;
        var children = this.searchFilter(structure.children);
        return React.createElement(ProjectSelectorDialog, { onClose: onClose, label: label, search: search, onSearch: this.handleSearch },
            structures.length > 1
                ? React.createElement(ProjectSelectorTabs, { value: page, onChange: this.handlePageChange, indicatorColor: 'primary' }, structures.map(function (s) { return React.createElement(ProjectSelectorTab, { key: s.key, value: s.key, label: s.label }); }))
                : null,
            React.createElement(ProjectSelectorHierarchy, null, structure
                ? React.createElement(Table, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, t('name')))),
                    React.createElement(TableBody, null, children.map(function (child) { return React.createElement(StructureElement, { key: child.key || child.value, indentation: 0, element: child, value: value, onChange: _this.handleChange }); })))
                : React.createElement(ProjectSelectorHierarchyLoading, null)),
            React.createElement(DialogActions, null,
                value.length > 0
                    ? React.createElement(Button, { size: "large", onClick: function () { return _this.setState({ value: [] }); } }, t('common:reset'))
                    : null,
                React.createElement(Button, { size: "large", onClick: onClose }, t('common:cancel')),
                React.createElement(Button, { size: "large", onClick: this.handleSelect }, t('common:select'))));
    };
    return HierarchySelectorDialog;
}(React.PureComponent));
export default withTranslation('fields')(HierarchySelectorDialog);
var StructureElement = memo(function (_a) {
    var _b, _c;
    var indentation = _a.indentation, element = _a.element, value = _a.value, onChange = _a.onChange;
    var recursivelySelected = isAChildSelected(element, value);
    var _d = useState(recursivelySelected), expanded = _d[0], setExpanded = _d[1];
    var handleExpand = useCallback(function () { return setExpanded(!expanded); }, [expanded, setExpanded]);
    var indeterminate = false;
    var selected = element.value !== undefined
        ? value.includes(element.value)
        : null;
    if ((_b = element.children) === null || _b === void 0 ? void 0 : _b.length) {
        var hasChildValues = getRecursiveValues(element).length > 0;
        if (hasChildValues) {
            if ((!element.value || selected) && isAllChildrenSelected(element, value)) {
                selected = true;
            }
            else {
                indeterminate = selected || recursivelySelected;
                selected = false;
            }
        }
    }
    var handleChange = useCallback(function () {
        var newValue = __spreadArrays(value);
        pullAll(newValue, __spreadArrays([element.value], getRecursiveValues(element)));
        if (!indeterminate && !selected) {
            if (element.value) {
                newValue.push(element.value);
            }
            newValue = newValue.concat(getRecursiveValues(element));
        }
        onChange(newValue);
    }, [selected, element, value, onChange]);
    return React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(ProjectSelectorHierarchyCell, { indentation: indentation, expanded: ((_c = element.children) === null || _c === void 0 ? void 0 : _c.length) > 0 ? expanded : null, onExpand: handleExpand, indeterminate: indeterminate, checked: selected, onCheck: handleChange },
                React.createElement(IconText, { icon: element.icon, text: element.label }))),
        expanded && element.children
            ? element.children.map(function (child) { return React.createElement(StructureElement, { key: child.key || child.value, element: child, indentation: indentation + 1, value: value, onChange: onChange }); })
            : null);
});
function isAChildSelected(element, value) {
    return intersection(getRecursiveValues(element), value).length > 0;
}
function isAllChildrenSelected(element, value) {
    var recursiveValues = getRecursiveValues(element);
    return recursiveValues.length > 0 && difference(recursiveValues, value).length === 0;
}
function getRecursiveValues(element) {
    var value = [];
    if (element.children) {
        for (var _i = 0, _a = element.children; _i < _a.length; _i++) {
            var child = _a[_i];
            value = __spreadArrays(value, [child.value], getRecursiveValues(child));
        }
    }
    return uniq(value.filter(function (v) { return v !== undefined; }));
}
