import React, {lazy} from "react";
import {LanguageProvider} from "../performer_portal/PerformerPortal";
import {useUserSession} from "../../data/UserSession";
import LoadingScreen from "../../components/loaders/LoadingScreen";
import {Route, Switch} from "react-router-dom";
import SchoolPortalBasePage from "./SchoolPortalBasePage";
import {PortalContext} from "../../utils/portal";
import SchoolPortalThemeProvider from "./SchoolPortalThemeProvider";


const SchoolPage = lazy(() => import("./pages/SchoolPage"));
const BookingView = lazy(() => import("./pages/booking/BookingView"));
const CulturalCalendarPage = lazy(() => import("./pages/CulturalCalendarPage"));
const UserPage = lazy(() => import("./pages/UserPage"));
const MessagesPage = lazy(() => import("./pages/MessagesPage"));

// TODO: Remove booking from prod

const page = <PortalContext.Provider value={"school"}>
    <SchoolPortalThemeProvider>
        <SchoolPortalBasePage>
            <React.Suspense fallback={<LoadingScreen/>}>
                <Switch>
                    <Route path={'/r/school/messages'} component={MessagesPage}/>
                    <Route path={'/r/school/user'} component={UserPage}/>
                    <Route path={'/r/school/:schoolId?/booking/:page?'} component={BookingView}/>
                    <Route path={'/r/school/:schoolId?/cultural-calendar/:page?'} component={CulturalCalendarPage}/>
                    <Route path={'/r/school/:schoolId?/:page?'} component={SchoolPage}/>
                    <Route component={CulturalCalendarPage}/>
                </Switch>
            </React.Suspense>
        </SchoolPortalBasePage>
    </SchoolPortalThemeProvider>
</PortalContext.Provider>;

export default function SchoolPortal() {
    const userSession = useUserSession();

    return <LanguageProvider language={userSession.user.language}>
        {page}
    </LanguageProvider>;
}