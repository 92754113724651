import React from 'react';
import { CircularProgress } from "@material-ui/core";
var indicator = React.createElement("div", { "data-testid": "loading-screen", style: {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    } },
    React.createElement(CircularProgress, null));
export default React.memo(function () { return indicator; });
