var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useMemo } from "react";
import OutlinedSelectField from "../../components/fields/OutlinedSelectField";
import { useTranslation } from "react-i18next";
import { SchoolType } from "../../../generated/globalTypes";
export default memo(function (props) {
    var _a = useTranslation(['fields', 'school_types']), t = _a.t, i18n = _a.i18n;
    var options = useMemo(function () {
        return [
            SchoolType.daycare,
            SchoolType.primary,
            SchoolType.lower_secondary,
            SchoolType.primary_and_lower_secondary,
            SchoolType.upper_secondary,
        ].map(function (value) { return ({ value: value, label: t('school_types:' + value) }); });
    }, [i18n.language]);
    return React.createElement(OutlinedSelectField, __assign({ nullText: "not_set", label: t('school_type'), options: options }, props));
});
