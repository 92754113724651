var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { isMobile } from "../../utils/MobileUtils";
var MobileBreakpointFunction = /** @class */ (function (_super) {
    __extends(MobileBreakpointFunction, _super);
    function MobileBreakpointFunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isMobile: isMobile()
        };
        _this.handleResize = function () { return _this.setState({ isMobile: isMobile() }); };
        return _this;
    }
    MobileBreakpointFunction.prototype.render = function () {
        return this.props.children(this.state.isMobile);
    };
    MobileBreakpointFunction.prototype.componentDidMount = function () {
        window.addEventListener('resize', this.handleResize);
    };
    MobileBreakpointFunction.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.handleResize);
    };
    return MobileBreakpointFunction;
}(React.PureComponent));
export default MobileBreakpointFunction;
