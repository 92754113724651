export var getStatusAplicationCouncilGroupEvaluation = function (status, t) {
    switch (status) {
        case "recommend":
            return t("common:recommend");
        case "not_recommended":
            return t("common:not_recommended");
        case "none":
            return t("common:none");
    }
};
export var getStatusAplicationCouncilGroupExternalEvaluator = function (status, t) {
    switch (status) {
        case "recommend":
            return t("common:recommend_external_group");
        case "not_recommended":
            return t("common:not_recommended_external_group");
    }
};
