function attachStyle(url, integrity) {
    const myCSS = document.createElement("link");
    myCSS.rel = "stylesheet";
    myCSS.href = url;
    myCSS.crossOrigin = 'anonymous';
    myCSS.integrity = integrity;
    document.head.insertBefore(myCSS, document.head.childNodes[document.head.childNodes.length - 1].nextSibling);
}

export function loadStyles() {
    attachStyle('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600');
    attachStyle('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;700&display=swap');
    attachStyle('https://fonts.googleapis.com/css?family=Material+Icons');
    attachStyle('https://use.fontawesome.com/releases/v5.8.2/css/all.css', 'sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay');
    attachStyle('/css/main.css');
}