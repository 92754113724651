var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { memo, useCallback, useEffect, useState } from "react";
import ScrollablePageStructure from "../../../components/page_structure/ScrollablePageStructure";
import ApplicationUnderConsiderationList from "../widgets/ApplicationUnderConsiderationList";
import LoadingScreen from "../../../components/loaders/LoadingScreen";
import { CircularProgress, debounce, Icon, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { jsonPost } from "../../../utils/JsonApi";
import PageConfiguration from "../../../utils/PageConfiguration";
import { useTranslation } from "react-i18next";
import InfiniteScrollableWrapper from "../../../components/InfiniteScrollableWrapper";
import SearchField from "../../../components/data_tables/filters/SearchFilter";
import { styleWCAG } from "../../../utils/WCAGThemeUtils";
import axios from "axios";
import { formatYearLevel } from "../../../common/YearLevelUtils";
import { formatDateTimeMed } from "../../../common/DateTimeFormats";
import { getStatusAplicationCouncilGroupEvaluation, getStatusAplicationCouncilGroupExternalEvaluator } from "../../../utils/StatusUtils";
import { getCulturalExpressions } from "../../../utils/CulturalExpressionUtil";
import SaveButton from "../../../components/SaveButton";
var Title = withStyles(function (theme) {
    var _a;
    return ({
        root: (_a = {
                marginBottom: '24px',
                textAlign: 'center'
            },
            _a[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _a)
    });
})(Typography);
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        wrapper: (_a = {
                maxWidth: '940px',
                padding: '40px 20px',
                margin: '0 auto',
                width: '100%'
            },
            _a[theme.breakpoints.down('xs')] = {
                padding: '20px',
            },
            _a)
    });
});
export default memo(function (_a) {
    var councilGroupId = _a.match.params.councilGroupId;
    var _b = useTranslation(['performer_portal', 'application_under_consider_fields', 'common']), t = _b.t, i18n = _b.i18n;
    var classes = useStyles();
    var classesv2 = styleWCAG();
    var title = t('applications_under_consideration');
    var _c = useState(0), offset = _c[0], setOffset = _c[1];
    var limit = useState(30)[0];
    var _d = useState(''), search = _d[0], setSearch = _d[1];
    var _e = useState(false), hasMore = _e[0], setHasMore = _e[1];
    var _f = useState([]), applications = _f[0], setApplications = _f[1];
    var _g = useState([['name', 'ASC']]), sort = _g[0], setSort = _g[1];
    var handleSearchApplications = useCallback(debounce(function (value, offset, loadMore, sort) {
        jsonPost('/api/web-authenticated/performer/applications/get_applications', {
            underConsideration: true,
            includeCouncilGroupAndBranchOffice: true,
            councilGroupId: councilGroupId,
            offset: offset,
            limit: limit,
            search: value,
            sort: sort
        }).then(function (response) {
            // @ts-ignore
            loadMore ? setApplications(function (applications) { return __spreadArrays(applications, response.data); }) : setApplications(response.data);
            // @ts-ignore
            setHasMore(!response || (response.page < response.totalPages));
        });
    }, 500), []);
    useEffect(function () { return handleSearchApplications('', 0, false, sort); }, [limit]);
    var loadMore = function () {
        setOffset(limit + offset);
        handleSearchApplications(search, limit + offset, true, sort);
    };
    var onSort = function (sort) {
        var isSort = sort ? sort : [['name', 'ASC']];
        setSort(isSort);
        setOffset(0);
        handleSearchApplications(search, 0, false, sort);
    };
    var header = [
        { label: t("application_under_consider_fields:reference_id") },
        { label: t("application_under_consider_fields:name") },
        { label: t("application_under_consider_fields:cultural_expression") },
        { label: t("application_under_consider_fields:target_group") },
        { label: t("application_under_consider_fields:my_evaluation_status") },
        { label: t("application_under_consider_fields:my_evaluation_text") },
        { label: t("application_under_consider_fields:last_seen") },
        { label: t("application_under_consider_fields:external_evaluator_group_evaluation_status") },
    ];
    var prepareDataToExport = function (application) {
        var personalEvaluation = application.personalEvaluations.length > 0 ? application.personalEvaluations[0] : null;
        var lastSeenTime = application.lastSeenTime.length > 0 ? application.lastSeenTime[0] : '';
        return [
            application.referenceNumber,
            application.name,
            getCulturalExpressions(application, i18n.language),
            formatYearLevel(application.yearLevelMinimum, application.yearLevelMaximum, application.yearLevelDescription, t),
            personalEvaluation ? getStatusAplicationCouncilGroupEvaluation(personalEvaluation.status, t) : null,
            personalEvaluation ? personalEvaluation.evaluation : null,
            formatDateTimeMed(lastSeenTime),
            getStatusAplicationCouncilGroupExternalEvaluator(application.externalEvaluatorGroupEvaluationStatus, t)
        ];
    };
    var exportToExcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {
                        title: 'applicationData',
                        columns: header,
                        rows: applications.map(function (datum) {
                            return {
                                // @ts-ignore
                                deleted: false,
                                columns: prepareDataToExport(datum)
                            };
                        })
                    };
                    return [4 /*yield*/, axios({
                            url: '/api/web-authenticated/data_tables/export/excel',
                            method: 'POST',
                            responseType: 'blob',
                            data: payload
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, window.URL.createObjectURL(new Blob([response.data]))];
            }
        });
    }); };
    var loader = React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px' } },
        React.createElement(CircularProgress, { size: 25 }));
    if (!applications)
        return React.createElement(LoadingScreen, null);
    return React.createElement(ScrollablePageStructure, null,
        React.createElement(PageConfiguration, { title: title }),
        React.createElement(InfiniteScrollableWrapper, { canLoadMore: hasMore, onLoadMore: loadMore },
            React.createElement("div", { className: classes.wrapper },
                React.createElement(Title, { className: classesv2.title, variant: 'h2' }, title),
                React.createElement("div", { style: { marginBottom: '30px' } },
                    React.createElement(SearchField, { id: "applicationUnderConsiderationPage-search", value: search, onChange: function (v) {
                            setSearch(v);
                            setApplications([]);
                            setOffset(0);
                            handleSearchApplications(v, 0, false, sort);
                        } }),
                    React.createElement(SaveButton, { startIcon: React.createElement(Icon, null, "cloud_download"), saveLabel: t("common:export_excel"), style: { margin: '20px 0px 20px 0px', float: 'right' }, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var url, link;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, exportToExcel()];
                                    case 1:
                                        url = _a.sent();
                                        link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'applicationData.xlsx');
                                        document.body.appendChild(link);
                                        link.click();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, t('common:export_excel'))),
                applications.length
                    ? React.createElement(ApplicationUnderConsiderationList, { applications: applications, isEvaluator: true, sort: sort, onSort: onSort })
                    : React.createElement(Typography, null, t('council_member_applications_empty')),
                hasMore ? loader : null)));
});
