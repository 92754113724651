import React, {memo} from "react";
import {makeStyles} from "@material-ui/core/styles";

const jumpToContentStyles = makeStyles({
    root: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        margin: '-1px',
        padding: '0',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        border: '0',
        zIndex: '-1',
        fontSize: '24px',

        '&:focus': {
            left: '0',
            top: '0',
            width: 'auto',
            height: 'auto',
            overflow: 'visible',
            clip: 'auto',
            outline: '5px auto -webkit-focus-ring-color',
            outlineOffset: '-2px',
            zIndex: '10000'
        }
    }
});

export default memo(() => {
    return <a href={'#content'} className={jumpToContentStyles().root}>Hopp til hovedinnhold</a>
});