import React, {lazy, memo, useState} from "react";
import {AppBar, Icon, IconButton, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import UserHeaderButton from "./UserHeaderButton";
import {withPageConfiguration} from "../../../utils/PageConfiguration";
import {MessageButton, HelpButton} from "../../performer_portal/header/PerformerPortalHeader";
import { useHeader } from "../../../data/repositories/HeaderRepository";
import { useTypedTranslation } from "../../../utils/i18n";

const AdministratorSearch = lazy(() => import("./AdministratorSearch"));
const AdministratorPortalDrawer = lazy(() => import("./AdministratorPortalDrawer"));

const useStyles = makeStyles({
    toolbar: {
        padding: '0 12px'
    },
    title: {
        marginLeft: '20px'
    },
    flex: {
        flex: '1'
    }
}, {name: 'AdministratorPortalHeader'});

export default withPageConfiguration(memo(props => {
    const classes = useStyles();
    const header = useHeader();
    const title = props.title || 'Kulturtanken Admin';
    const [open, setOpen] = useState(false);
    const hideLeftMenu = header?.hideLeftMenu ?? false;
    const { t } = useTypedTranslation();

    return <React.Suspense fallback={null}>
        <AdministratorPortalDrawer
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
        <AppBar position="relative">
            <Toolbar className={classes.toolbar} variant={"dense"}>
                {hideLeftMenu ? null : <IconButton color={'inherit'} onClick={() => setOpen(true)}>
                    <Icon aria-label={t('common', 'menu_icon')}>menu</Icon>
                </IconButton>}

                <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                    {title}
                </Typography>

                <div className={classes.flex}/>

                <AdministratorSearch/>

                <div className={classes.flex}/>

                <HelpButton portal={'administrator'}/>

                <MessageButton portal={'administrator'}/>

                <UserHeaderButton
                    portal={'administrator'}
                />
            </Toolbar>
        </AppBar>
    </React.Suspense>;
}));