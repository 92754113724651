var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * if Option has search in filter. Encode search with encodeURIComponent func and return self
 * Example: 'шеллы' => '%D1%88%D0%B5%D0%BB%D0%BB%D1%8B'
 * @param options
 */
var encodeUTF8CharactersInSearchField = function (options) {
    var _options = __assign({}, options);
    if (options.filters) {
        var _filters = __assign({}, options.filters);
        if (_filters.search)
            _filters.search = encodeURIComponent(_filters.search);
        _options.filters = _filters;
    }
    return _options;
};
/**
 * if Option has search in filter. Decode search with decodeURIComponent func and return self
 * Example: '%D1%88%D0%B5%D0%BB%D0%BB%D1%8B' => 'шеллы'
 * @param options
 */
var decodeUTF8CharactersInSearchField = function (options) {
    var _a;
    if ((_a = options.filters) === null || _a === void 0 ? void 0 : _a.search)
        options.filters.search = decodeURIComponent(options.filters.search);
    return options;
};
/**
 * Use to stringify object
 * Options is usually state of component
 * Refer to data table list
 * Accept any as long as match type Options
 * Example:
 * {
        sort: this.props.defaultSort || [['name', 'ASC']],
        limit: 20,
        filters: {
            search: 'шеллы'',
        },
        refresh: true,
    }
 */
export default {
    stringify: function (options) {
        return btoa(JSON.stringify(encodeUTF8CharactersInSearchField(options)));
    },
    parse: function () {
        try {
            return decodeUTF8CharactersInSearchField(JSON.parse(atob(window.location.hash.substr(1))));
        }
        catch (e) {
            return {};
        }
    }
};
