import React from "react";
import * as PropTypes from "prop-types";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTable from "./EnhancedTable";
import {debounce, xor} from "lodash-es";
import DataTableOptions from "./DataTableOptions";
import DataTableScrollContainer from "./DataTableScrollContainer";
import DataTablePage from "./DataTablePage";
import DataProvider from "../DataProvider";
import PageConfiguration from "../../utils/PageConfiguration";
import {withTranslation} from "react-i18next";
import {structureElementPrefix} from "../../widgets/cultural_institution/CulturalInstitutionSelectorModal";


@withTranslation('fields')
class DataTable extends React.PureComponent {
    static propTypes = {
        selectable: PropTypes.bool,
        columns: PropTypes.array.isRequired,
        filters: PropTypes.arrayOf(PropTypes.node).isRequired,
        defaultFilterValues: PropTypes.object,
        loadData: PropTypes.func.isRequired,
        onNew: PropTypes.func,
    };

    state = {
        sort: this.props.defaultSort || [['name', 'ASC']],
        limit: 20,
        filters: {
            ...this.props.defaultFilterValues
        },
        refresh: true,
        ...DataTableOptions.parse()
    };
    updateHash = debounce(() => {
        const {data, loading, ...options} = this.state;
        const hash = '#' + DataTableOptions.stringify(options);

        if (window.location.hash !== hash)
            window.location.replace(hash);
    }, 500);
    loadMore = debounce(() => {
        this.setState({limit: this.state.limit + 20}, this.updateData);
    }, 1000);

    handleFilterChange = (filterValues) => this.setState(({filters}) => ({filters: {...filters, ...filterValues}}));

    handleSortChange = (sort) => {
        this.setState({sort});
    };

    handleToggleSelectedIds = (id) => this.handleFilterChange({
        selectedIds: xor(this.state.selectedIds || [], [id]),
        selectedAll: false
    });

    handleToggleAllSelected = () => {
        this.setState({selectedIds: null, selectedAll: !this.state.selectedAll});
    };

    handleVisibleColumnsChange = visibleColumns => this.setState({visibleColumns});

    handleOpenRow = (id) => this.setState({lastOpenId: id});

    render() {
        const {title, headerActions, filters, columns, onNew, showFilteredNumber} = this.props;
        let {visibleColumns, selectedIds, selectedAll, refresh, lastOpenId, ...dataOptions} = this.state;

        if (!visibleColumns) {
            visibleColumns = columns.filter(c => c.defaultVisible && !c.forExportOnly).map(c => c.label);

            if (visibleColumns.length === 0)
                visibleColumns = columns.map(c => c.label);
        }

        return <DataTablePage>
            {!showFilteredNumber && title ? <PageConfiguration title={title}/> : null}

            <EnhancedTableHead
                onNew={onNew}
                onDownload={this.handleDownload}

                columns={columns}
                visibleColumns={visibleColumns}
                onVisibleColumnsChange={this.handleVisibleColumnsChange}

                filters={(filters || []).map(
                    (filter) => <FilterHandler
                        key={filter.key}
                        FilterComponent={filter}
                        value={this.state.filters[filter.key] ? this.state.filters[filter.key] : false}
                        onChange={this.handleFilterChange}
                    />
                )}
                headerActions={headerActions}
                clearFilterDisabled={Object.keys(this.state.filters).length === 0}
                clearFilters={() => this.setState({filters: {}})}
                title={title}
                getData={() => this.props.loadData({
                    ...dataOptions,
                    ...dataOptions.filters,
                    skip: null,
                    limit: null
                })}
            />

            <DataProvider query={dataOptions} loadData={this.loadData} refresh={refresh}>
                {data => <>
                    {showFilteredNumber
                        ? <PageConfiguration
                            title={title + ' ' + (data ? '– ' + data.totalHits + '/' + data.availableHits + ' ' + this.props.t('filtered') : '')}/>
                        : null}

                    <DataTableScrollContainer
                        page={this.state.page}
                        loadMore={this.loadMore}
                        hasMore={!!data?.hasMore}
                    >
                        <EnhancedTable
                            selectable={this.props.selectable}
                            selectedIds={this.state.selectedIds}
                            columns={this.props.columns}
                            visibleColumns={visibleColumns}
                            rowLink={this.props.rowLink}
                            openInNewTab={this.props.openInNewTab}
                            data={data?.rows}
                            sort={this.state.sort}
                            onSort={this.handleSortChange}
                            onToggleSelectedIds={this.handleToggleSelectedIds}
                            lastOpenId={this.state.lastOpenId}
                            onRowClick={this.handleOpenRow}
                            selectedAll={this.state.selectedAll}
                            onToggleAllSelected={this.handleToggleAllSelected}
                            title={this.props.title}
                        />
                    </DataTableScrollContainer>
                </>
                }
            </DataProvider>
        </DataTablePage>;
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.updateHash();
        if (prevProps.refresh !== this.props.refresh) {
            this.setState({refresh: !this.state.refresh});
        }
    }

    loadData = async (data) => {
        const {loadData} = this.props;
        const {limit, filters} = this.state;
        data.filters = handleStructureFilter(data.filters);

        return await loadData({
            limit,
            ...handleStructureFilter(filters),
            ...data,
        });
    };

    // handleDownload = async () => {
    //     const {visibleColumns} = this.state;
    //     const {columns, title} = this.props;
    //     const data = await this.loadData({limit: null, skip: null});
    //
    //     await exportToExcel({
    //         title,
    //         columns,
    //         data,
    //         visibleColumns,
    //         // filters: this.getFilters()
    //     });
    // }
}

export function handleStructureFilter (filters) {
    // Clone filter object and filter CI ids with prefix
    let newFilters = JSON.parse(JSON.stringify(filters));
    if (filters.culturalInstitutionIds)
        newFilters.culturalInstitutionIds = filters.culturalInstitutionIds
            .filter(id => id.toString().startsWith(structureElementPrefix.CULTURAL_INSTITUTION))
            .map(id => parseInt(id.substring(2)))
    return newFilters;
}

export default DataTable;

class FilterHandler extends React.PureComponent {
    handleChange = value => this.props.onChange({[this.props.FilterComponent.key]: value});

    render(): React.ReactNode {
        const {value, FilterComponent} = this.props;

        const flex = FilterComponent.flex || 1;

        const styles = FilterComponent.inline
            ? {}
            : {flex, minWidth: (flex * (FilterComponent.key === 'messageSubscriberIds' ? 250 : 200)) + 'px', maxWidth: (flex * 300) + 'px'};


        return <div style={{padding: '8px 8px', ...styles}}>
            <FilterComponent
                value={value}
                onChange={this.handleChange}
            />
        </div>;
    }
}