var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { jsonPost } from "../../utils/JsonApi";
export var spreadSheetDataTypes = {
    STRING: 's',
    NUMERIC: 'n',
};
export function exportToExcel(props, t) {
    return __awaiter(this, void 0, void 0, function () {
        var payload, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = serializeTable(props, t);
                    if (props === null || props === void 0 ? void 0 : props.shouldRunByCron) {
                        return [2 /*return*/, jsonPost('/api/web-authenticated/data_tables/export/excel', __assign(__assign({}, payload), { shouldRunByCron: true }))];
                    }
                    ;
                    return [4 /*yield*/, axios({
                            url: '/api/web-authenticated/data_tables/export/excel',
                            method: 'POST',
                            responseType: 'blob',
                            data: payload
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, window.URL.createObjectURL(new Blob([response.data]))];
            }
        });
    });
}
export function ProgramsExportToExcel(props, t) {
    return __awaiter(this, void 0, void 0, function () {
        var url, payload, exportByCronData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = '/api/web-authenticated/administrator/programs/export_to_excel';
                    payload = __assign(__assign({}, props.data), {
                        title: props.title,
                        columns: props.columns.map(function (_a) {
                            var label = _a.label, dataType = _a.dataType, numberOfDecimalDigits = _a.numberOfDecimalDigits, field = _a.field;
                            return { label: label, dataType: dataType, numberOfDecimalDigits: numberOfDecimalDigits, field: field };
                        })
                    });
                    return [4 /*yield*/, jsonPost('/api/web-authenticated/administrator/programs/get_export_by_cron_data', payload)];
                case 1:
                    exportByCronData = _a.sent();
                    if (exportByCronData === null || exportByCronData === void 0 ? void 0 : exportByCronData.shouldRunByCron) {
                        return [2 /*return*/, jsonPost(url, __assign(__assign({}, payload), {
                                limit: exportByCronData.availableHits,
                                shouldRunByCron: true
                            }))];
                    }
                    ;
                    return [4 /*yield*/, axios({
                            url: url,
                            method: 'POST',
                            responseType: 'blob',
                            data: payload
                        })];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, window.URL.createObjectURL(new Blob([response.data]))];
            }
        });
    });
}
function formatVariable(value, t) {
    if (value === true)
        return t('fields', 'yes');
    if (value === false)
        return t('fields', 'no');
    return value;
}
function serializeTable(_a, t) {
    var title = _a.title, columns = _a.columns, data = _a.data;
    columns = columns
        .filter(function (_a) {
        var variable = _a.variable, stringify = _a.stringify, exportPayload = _a.exportPayload;
        return variable || stringify || exportPayload;
    });
    return {
        title: title,
        columns: columns
            .map(function (_a) {
            var label = _a.label, dataType = _a.dataType, numberOfDecimalDigits = _a.numberOfDecimalDigits;
            return { label: label, dataType: dataType, numberOfDecimalDigits: numberOfDecimalDigits };
        }),
        rows: data.rows.map(function (datum) {
            return {
                // @ts-ignore
                deleted: !!(datum.deleted || datum.anchorDeleted || datum.anchor_deleted),
                columns: columns.map(function (_a) {
                    var variable = _a.variable, stringify = _a.stringify, exportPayload = _a.exportPayload;
                    if (exportPayload)
                        return exportPayload(datum);
                    if (stringify)
                        return stringify(datum);
                    if (variable)
                        return formatVariable(datum[variable], t);
                    return null;
                })
            };
        })
    };
}
