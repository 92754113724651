var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createMuiTheme, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { options } from '../../utils/AdministratorPortalThemeProvider';
var theme = createMuiTheme(__assign(__assign({}, options), { overrides: __assign(__assign({}, options.overrides), { MuiTabs: {
            root: {
                borderBottom: '1px solid #ddd',
                padding: '0 18px'
            }
        }, MuiTab: {
            root: {
                minWidth: '0 !important',
                minHeight: '50px',
                marginRight: '20px',
                marginBottom: '-1px',
                padding: '8px !important',
            },
            wrapper: {
                textTransform: 'none'
            },
        }, MuiAppBar: {
            root: {
                minHeight: 56,
            },
            colorPrimary: {
                backgroundColor: '#e8eafe'
            }
        }, MuiToolbar: {
            root: {
                color: '#0033da'
            }
        } }) }));
export { theme };
var SchoolPortalThemeProvider = function (_a) {
    var children = _a.children;
    return React.createElement(ThemeProvider, { theme: theme },
        React.createElement(CssBaseline, null),
        children);
};
export default SchoolPortalThemeProvider;
