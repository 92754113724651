var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RoleType } from "../../../../generated/globalTypes";
import React, { memo, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import DataTable from "../../../components/data_tables/DataTable";
import SearchFilter from "../../../components/data_tables/filters/SearchFilter";
import { IncludeChildrenReadableBranchOfficeFilter, } from "../../../components/data_tables/filters/SelectorModalFilters";
import { jsonPost } from "../../../utils/JsonApi";
import nb from "../../../components/fields/countries.nb.json";
import { formatDateTimeMed } from "../../../common/DateTimeFormats";
import { createDataTableFilter } from "../../../components/data_tables/filters/utils";
import OutlinedSelectField from "../../../components/fields/OutlinedSelectField";
import RoleTypes from "../../../common/RoleTypes";
import 'core-js/features/object/from-entries';
import SchoolTypeField from "../../../widgets/school_institution_type/SchoolTypeField";
var countriesMap = Object.fromEntries(nb.map(function (_a) {
    var code = _a.code, name = _a.name;
    return [code, name];
}));
var RoleTypeField = memo(function (props) {
    var _a = useTranslation(['role_types', 'fields']), t = _a.t, i18n = _a.i18n;
    var options = useMemo(function () {
        return Object.values(RoleTypes)
            .filter(function (r) { return r !== "P"; })
            .map(function (value) { return ({ value: value, label: t(value) }); });
    }, [i18n.language]);
    return React.createElement(OutlinedSelectField, __assign({ label: t('fields:role_type'), options: options }, props));
});
var UserRoleListPage = /** @class */ (function (_super) {
    __extends(UserRoleListPage, _super);
    function UserRoleListPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filters = [
            SearchFilter,
            IncludeChildrenReadableBranchOfficeFilter,
            createDataTableFilter(RoleTypeField, { key: "roleTypes" }, { multiple: true }),
            createDataTableFilter(SchoolTypeField, { key: "schoolTypes" }, { multiple: true })
        ];
        _this.columns = [
            {
                label: _this.props.t('branch_office'),
                variable: 'branch_office_names',
                sortKey: 'branch_office_names_sort',
                defaultVisible: true,
                linkify: function (datum) { return (datum.branch_office_ids.map(function (id) { return "/r/administrator/branch-office/" + id; })); },
            },
            {
                label: _this.props.t('first_name'),
                variable: 'user_first_name',
                sortKey: 'user_first_name_sort',
                defaultVisible: true
            },
            {
                label: _this.props.t('last_name'),
                variable: 'user_last_name',
                sortKey: 'user_last_name_sort',
                defaultVisible: true
            },
            {
                label: _this.props.t('email'),
                variable: 'user_email',
                sortKey: 'user_email_sort',
                defaultVisible: true,
                linkify: function (datum) { return ("mailto:" + datum.user_email); },
            },
            {
                label: _this.props.t('phone_number'),
                variable: 'user_phone_number',
                sortKey: 'user_phone_number_sort',
                defaultVisible: true
            },
            {
                label: _this.props.t('street'),
                variable: 'user_address_street',
                sortKey: 'user_address_street',
                defaultVisible: true
            },
            {
                label: _this.props.t('postal_code'),
                variable: 'user_address_postal_code',
                sortKey: 'user_address_postal_code',
                defaultVisible: true
            },
            {
                label: _this.props.t('locality'),
                variable: 'user_address_locality',
                sortKey: 'user_address_locality',
                defaultVisible: true
            },
            {
                label: _this.props.t('date_created'),
                stringify: function (datum) { return formatDateTimeMed(datum.created_timestamp); },
                sortKey: 'created_timestamp',
            },
            {
                label: _this.props.t('created_by'),
                variable: 'created_by_display_name',
                sortKey: 'created_by_display_name',
            },
            {
                label: _this.props.t('nationality'),
                stringify: function (datum) { return countriesMap[datum.user_nationality || '']; },
                sortKey: 'user_nationality'
            },
            {
                label: _this.props.t('language'),
                stringify: function (datum) { return datum.user_language ? _this.props.t('languages:' + datum.user_language) : null; },
                sortKey: 'user_language'
            },
            {
                label: _this.props.t('role_type'),
                stringify: function (datum) { return _this.props.t('role_types:' + datum.role_type); },
                sortKey: 'role_type_sort',
                defaultVisible: true
            },
            {
                label: _this.props.t('owner'),
                variable: 'owner_name_sort',
                sortKey: 'owner_name_sort',
                linkify: function (datum) {
                    switch (datum.role_type) {
                        case RoleType.B:
                        case RoleType.M:
                        case RoleType.F:
                            return '/r/administrator/branch-office/' + datum.owner_id;
                        case RoleType.C:
                            return '/r/administrator/cultural-institution/' + datum.owner_id;
                        case RoleType.S:
                        case RoleType.T:
                            return '/r/administrator/school/' + datum.owner_id;
                        case RoleType.W:
                            return '/r/administrator/wordpress/' + datum.owner_id;
                        default:
                            return null;
                    }
                },
                defaultVisible: true
            },
            {
                label: _this.props.t('school_type'),
                sortKey: 'school_type_sort',
                stringify: function (_a) {
                    var school_type = _a.school_type;
                    return school_type ? _this.props.t('school_types:' + school_type) : null;
                },
            },
        ];
        _this.rowLink = function (datum) { return '/r/administrator/user/' + datum.user_id; };
        _this.loadData = function (filters) { return jsonPost('/api/web-authenticated/administrator/user_roles/data_table', filters); };
        return _this;
    }
    UserRoleListPage.prototype.render = function () {
        return React.createElement(DataTable, { title: this.props.t('fields:user_roles'), defaultSort: [["user_first_name_sort", "ASC"]], columns: this.columns, filters: this.filters, loadData: this.loadData, rowLink: this.rowLink, showFilteredNumber: true, openInNewTab: true });
    };
    return UserRoleListPage;
}(React.PureComponent));
export default withTranslation(['fields', 'school_types'])(UserRoleListPage);
