var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useTypedTranslation } from "../utils/i18n";
import { useTranslation } from "react-i18next";
export var yearLevels = ['KG1', 'KG2', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'VG1', 'VG2', 'VG3'];
export function formatYearLevel(minimum, maximum, description, tFunc) {
    if (typeof (minimum) !== 'number' && typeof (maximum) !== 'number' && (!minimum || !maximum))
        return null;
    var formattedYearLevel = yearLevels[minimum ? minimum + 1 : 1] + '. – ' + yearLevels[maximum ? maximum + 1 : 1]
        + '.' + (description ? ': ' + description : '');
    return tFunc ? translateKindergarten(formattedYearLevel, tFunc) : replaceKindergarten(formattedYearLevel);
}
export function formatYearLevels(language, strings, input) {
    if (input.length === 0)
        return '';
    var yearLevelString = strings[0].toLocaleLowerCase();
    var kindergartenString = strings[1].toLocaleLowerCase();
    var formatYearLevelText = function (yearLevel) {
        switch (yearLevel) {
            case -1:
                return kindergartenString + ' 0-2';
            case 0:
                return kindergartenString + ' 3-6';
            default:
                return formatEnumeration(language, yearLevels[yearLevel + 1]) + ' ' + yearLevelString;
        }
    };
    input = __spreadArrays(input).sort(function (a, b) { return a - b; });
    var first = input[0];
    var last = input[input.length - 1];
    if (input.length === 1)
        return formatYearLevelText(input[0]);
    if (input.length > 2) {
        if ((last - first) === (input.length - 1)) {
            var startYearLevelString = formatYearLevelText(first);
            var endYearLevelString = formatEnumeration(language, yearLevels[last + 1]) + '. ' + yearLevelString;
            return startYearLevelString + '. – ' + endYearLevelString;
        }
    }
    return input.map(function (v) { return formatYearLevelText(v); }).join(', ');
}
export function formatEnumeration(language, number) {
    if (language === "en") {
        if (typeof number === "string" && !isNaN(parseInt(number))) {
            number = parseInt(number);
        }
        if (number === 1)
            return "1st";
        if (number === 2)
            return "2nd";
        if (number === 3)
            return "3rd";
        return number + "th";
    }
    return number + ".";
}
export function replaceKindergarten(formattedYearLevel, tFunc) {
    if (tFunc === void 0) { tFunc = null; }
    return translateKindergarten(formattedYearLevel !== null && formattedYearLevel !== void 0 ? formattedYearLevel : '', tFunc !== null && tFunc !== void 0 ? tFunc : useTypedTranslation().t);
}
export function translateKindergarten(str, tFunc) {
    return str
        .replace(/KG1./g, tFunc('common:kindergarten') + ' 0-2')
        .replace(/KG2./g, tFunc('common:kindergarten') + ' 3-6');
}
export var formatYearLevelLabel = function (yearLevel) {
    switch (yearLevel) {
        case -1:
            return useTranslation('common').t('kindergarten') + ' 0-2';
        case 0:
            return useTranslation('common').t('kindergarten') + ' 3-6';
        default:
            return yearLevels[yearLevel + 1];
    }
};
