var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormControl, InputLabel, OutlinedInput, Select } from "@material-ui/core";
export default React.memo(function (_a) {
    var id = _a.id, value = _a.value, onClick = _a.onClick, label = _a.label, placeholder = _a.placeholder, props = __rest(_a, ["id", "value", "onClick", "label", "placeholder"]);
    var inputLabelRef = React.useRef(null);
    var _b = React.useState(0), labelWidth = _b[0], setLabelWidth = _b[1];
    React.useEffect(function () {
        setLabelWidth(inputLabelRef.current.offsetWidth);
    }, []);
    return React.createElement(FormControl, __assign({ fullWidth: true }, props, { variant: "outlined" }),
        React.createElement(InputLabel, { ref: inputLabelRef, id: id ? id + '-label' : undefined, shrink: placeholder ? true : undefined, htmlFor: id }, label),
        React.createElement(Select, { labelId: id ? id + '-label' : undefined, inputProps: { id: id }, fullWidth: true, open: false, value: value || placeholder || '', renderValue: function () {
                if (value)
                    return value;
                else if (placeholder)
                    return React.createElement("span", { style: { color: "#aaa" } }, placeholder);
                else
                    return "";
            }, onOpen: onClick, input: React.createElement(OutlinedInput, { labelWidth: labelWidth }), "aria-label": label, placeholder: placeholder }));
});
