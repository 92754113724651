import React from "react";
import SchoolPortalHeader from "./header/SchoolPortalHeader";
import {makeStyles} from "@material-ui/core/styles";
import JumpToContentLink from "../../widgets/JumpToContentLink";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        left: '0',
        top: '0',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
    },
    content: {
        position: 'relative',
        flex: '1',
    }
}, {name: 'SchoolPortalBasePage'});

const SchoolPortalBasePage = ({children}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <JumpToContentLink/>
        <SchoolPortalHeader/>
        <div role={"main"} id={"content"} className={classes.content}>
            {children}
        </div>
    </div>;
};

export default SchoolPortalBasePage;