var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@material-ui/core";
import { renderModal } from "./AnchorDiv";
import { useTranslation } from "react-i18next";
export function xmlHttpPost(url, data) {
    if (data instanceof FormData) {
        return formDataPost(url, data);
    }
    return jsonPost(url, data);
}
export function jsonPost(url, data) {
    return new Promise(function (solve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            xmlHttpHandleStatusResponse(xmlhttp, solve, reject);
        };
        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.send(JSON.stringify(data));
    });
}
export function formDataPost(url, data) {
    return new Promise(function (solve, reject) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            xmlHttpHandleStatusResponse(xmlhttp, solve, reject);
        };
        xmlhttp.open("POST", url, true);
        xmlhttp.withCredentials = true;
        xmlhttp.send(data);
    });
}
var xmlHttpHandleStatusResponse = function (xmlhttp, solve, reject) {
    if (xmlhttp.readyState === 4) {
        switch (xmlhttp.status) {
            case 200:
                var response = void 0;
                try {
                    response = JSON.parse(xmlhttp.responseText);
                }
                catch (e) {
                    response = xmlhttp.status;
                }
                solve(response);
                break;
            case 401:
                renderModal(UnauthorizedModal);
                reject(new Error('Status code: 401, ' + xmlhttp.responseText));
                break;
            case 403:
                renderModal(NetworkAccessDeniedErrorHttp);
                reject(new Error('Status code: 403, ' + xmlhttp.responseText));
                break;
            case 291: //download file from url direclty
                solve(xmlhttp.responseText);
                break;
            case 292: //message with translations
                var transRes_1 = JSON.parse(xmlhttp.responseText);
                renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: useTranslation(transRes_1.transDomain).t(transRes_1.transKey, transRes_1.args) }, props)); });
                solve(transRes_1);
                break;
            case 280:
                renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: xmlhttp.responseText }, props)); });
                try {
                    response = JSON.parse(xmlhttp.responseText);
                }
                catch (e) {
                    response = xmlhttp.status;
                }
                solve(response);
                break;
            case 281:
                renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: 'Feil på serveren. Feilen har blitt sendt til driftstekniker.' }, props)); });
                try {
                    response = JSON.parse(xmlhttp.responseText);
                }
                catch (e) {
                    response = xmlhttp.status;
                }
                solve(response);
                break;
            case 290:
            case 490:
                renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: xmlhttp.responseText }, props)); });
                reject(new Error('Status code: ' + xmlhttp.status + ', ' + xmlhttp.responseText));
                break;
            default:
                renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: 'Feil på serveren. Feilen har blitt sendt til driftstekniker.' }, props)); });
                reject(new Error('Invalid server status code: ' + xmlhttp.status));
        }
    }
};
export function abortableJsonPost(url, data) {
    var xmlhttp = new XMLHttpRequest();
    var promise = new Promise(function (solve, reject) {
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4) {
                switch (xmlhttp.status) {
                    case 0: //aborted
                        reject('aborted');
                        break;
                    case 200:
                        var response = void 0;
                        try {
                            response = JSON.parse(xmlhttp.responseText);
                        }
                        catch (e) {
                            response = xmlhttp.status;
                        }
                        solve(response);
                        break;
                    case 401:
                        renderModal(UnauthorizedModal);
                        reject(new Error('Status code: 401, ' + xmlhttp.responseText));
                        break;
                    case 403:
                        renderModal(NetworkAccessDeniedErrorHttp);
                        reject(new Error('Status code: 403, ' + xmlhttp.responseText));
                        break;
                    case 291: //download file from url direclty
                        solve(xmlhttp.responseText);
                        break;
                    case 292: //message with translations
                        var transRes_2 = JSON.parse(xmlhttp.responseText);
                        renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: useTranslation(transRes_2.transDomain).t(transRes_2.transKey, transRes_2.args) }, props)); });
                        solve(transRes_2.params);
                        break;
                    case 280:
                        renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: xmlhttp.responseText }, props)); });
                        try {
                            response = JSON.parse(xmlhttp.responseText);
                        }
                        catch (e) {
                            response = xmlhttp.status;
                        }
                        solve(response);
                        break;
                    case 290:
                    case 490:
                        renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: xmlhttp.responseText }, props)); });
                        reject(new Error('Status code: ' + xmlhttp.status + ', ' + xmlhttp.responseText));
                        break;
                    default:
                        renderModal(function (props) { return React.createElement(SnackbarResponseModal, __assign({ message: 'Feil på serveren. Feilen har blitt sendt til driftstekniker.' }, props)); });
                        reject(new Error('Invalid server status code: ' + xmlhttp.status));
                }
            }
        };
        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.send(JSON.stringify(data));
    });
    promise.abort = function () {
        xmlhttp.abort();
    };
    return promise;
}
export var NetworkAccessDeniedErrorHttp = function () { return React.createElement(Dialog, { open: true },
    React.createElement(DialogTitle, null, "Access denied"),
    React.createElement(DialogContent, null,
        React.createElement(DialogContentText, null, "Your user does not have access to this resource.")),
    React.createElement(DialogActions, null,
        React.createElement(Button, { color: "primary", href: '/' }, "Close"))); };
export var UnauthorizedModal = function () {
    var onClose = function () { return window.location.reload(); };
    return React.createElement(Dialog, { open: true, onClose: onClose },
        React.createElement(DialogTitle, null, "Ikke innlogget"),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, null, "Din sesjon er utl\u00F8pt. Trykk nedenfor for \u00E5 logge inn p\u00E5 nytt")),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", onClick: onClose }, "Logg inn")));
};
export var ErrorModal = function () { return React.createElement(Dialog, { open: true },
    React.createElement(DialogContent, null,
        React.createElement(DialogContentText, null, "Feil p\u00E5 serveren. Feilen har blitt sendt til driftstekniker.")),
    React.createElement(DialogActions, null,
        React.createElement(Button, { color: "primary", href: '/' }, "Lukk"))); };
var SnackbarResponseModal = function (props) { return React.createElement(Snackbar, __assign({ open: true, autoHideDuration: 3000 }, props)); };
export { SnackbarResponseModal };
export var LoadingState = {
    IDLE: 'idle',
    LOADING: 'loading',
    DONE: 'done',
    FAILED: 'failed'
};
export function useJsonResource(url, data, options) {
    var _a = useState(null), response = _a[0], setResponse = _a[1];
    var _b = useState(LoadingState.IDLE), status = _b[0], setStatus = _b[1];
    var json = JSON.stringify(data);
    var loadData = useCallback(function (params) {
        setStatus(LoadingState.LOADING);
        if (options === null || options === void 0 ? void 0 : options.skip) {
            return new Promise(function (solve) { return solve(); });
        }
        return jsonPost(url, __assign(__assign({}, data), (params !== null && params !== void 0 ? params : {})))
            .then(function (response) {
            setStatus(LoadingState.DONE);
            setResponse(response);
        })
            .catch(function (e) { return setStatus(LoadingState.FAILED); });
    }, [url, json, options === null || options === void 0 ? void 0 : options.skip]);
    useEffect(function () {
        if (url) {
            // noinspection JSIgnoredPromiseFromCall
            loadData();
        }
    }, [json]);
    return [response, loadData, status];
}
export var prepareGetQuery = function (filters) {
    var keys = Object.keys(filters);
    var filterValues = keys
        .filter(function (key) {
        return filters[key] === 0 ||
            (Array.isArray(filters[key]) && filters[key].length !== 0) ||
            (!Array.isArray(filters[key]) && !!filters[key]);
    })
        .map(function (key) {
        var filterKey = JSON.parse(JSON.stringify(filters[key]));
        if (typeof filterKey === "object") {
            filterKey = filterKey.join(",");
        }
        return key + "=" + filterKey;
    });
    var behind = filterValues.join("&");
    return behind ? "" + behind : '';
};
