var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, useCallback } from "react";
import { Dialog, Icon, InputAdornment, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
export default memo(function (_a) {
    var onClose = _a.onClose, label = _a.label, search = _a.search, onSearch = _a.onSearch, children = _a.children;
    return React.createElement(Dialog, { open: true, onClose: onClose, maxWidth: false },
        React.createElement("div", { style: {
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'stretch'
            } },
            React.createElement("div", { style: { padding: '24px 16px' } },
                React.createElement(Typography, { variant: "h6", color: "inherit" }, label)),
            React.createElement("div", { style: { padding: '16px' } },
                React.createElement(SearchField, { value: search, onChange: onSearch })),
            React.createElement("div", null, children)));
});
var SearchField = memo(function (_a) {
    var onChange = _a.onChange, props = __rest(_a, ["onChange"]);
    var t = useTranslation('fields').t;
    var handleChange = useCallback(function (event) { return onChange(event.target.value); }, [onChange]);
    return React.createElement(TextField, __assign({ autoFocus: true, fullWidth: true, label: t('search'), onChange: handleChange, variant: "outlined", InputProps: {
            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                React.createElement(Icon, null, "search"))),
        } }, props));
});
