var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { memo, useEffect, useState } from "react";
import { jsonPost } from "../../utils/JsonApi";
import LoadingModal from "../../components/loaders/LoadingModal";
import { getMyBranchOffices, getMyBranchOfficesIncludingChildren, isSystemAdministrator } from "../../data/UserSession";
import { getBranchOffices } from "../../data/repositories/BranchOfficeRepository";
import HierarchySelectorDialog from "../../components/project_select/HierarchySelectorDialog";
import { intersection } from "lodash-es";
export var structureElementPrefix = {
    BRANCH_OFFICE: 'b-',
    CULTURAL_INSTITUTION: 'c-'
};
function generateStructure_recursive(branchOffices, culturalInstitutions, parentBranchOfficeId) {
    var childBranchOffices = branchOffices
        .filter(function (b) { return b.parentBranchOfficeId === parentBranchOfficeId; });
    if (parentBranchOfficeId === null) {
        var branchOfficeIds_1 = branchOffices.map(function (b) { return b.id; });
        branchOffices.forEach(function (b) {
            if (b.parentBranchOfficeId) {
                if (!branchOfficeIds_1.includes(b.parentBranchOfficeId)) {
                    childBranchOffices.push(b);
                }
            }
        });
    }
    var formatted = childBranchOffices.map(function (branchOffice) {
        var children = generateStructure_recursive(branchOffices, culturalInstitutions, branchOffice.id);
        var childCulturalInstitutions = culturalInstitutions
            .filter(function (c) { return c.branchOfficeIds.includes(branchOffice.id); });
        var formatted = {
            key: structureElementPrefix.BRANCH_OFFICE + branchOffice.id,
            icon: 'business',
            label: branchOffice.name,
            children: childCulturalInstitutions.map(formatCulturalInstitution)
        };
        if (children.length) {
            return {
                key: branchOffice.id,
                icon: 'folder',
                label: branchOffice.name,
                children: __spreadArrays([formatted], children)
            };
        }
        else {
            return formatted;
        }
    });
    if (parentBranchOfficeId === null) {
        formatted = formatted.concat(culturalInstitutions
            .filter(function (c) { return c.branchOfficeIds.length === 0; })
            .map(formatCulturalInstitution));
    }
    return formatted;
}
function formatCulturalInstitution(culturalInstitution) {
    return {
        key: 'c-' + culturalInstitution.id,
        value: structureElementPrefix.CULTURAL_INSTITUTION + culturalInstitution.id,
        icon: 'people',
        label: culturalInstitution.name
    };
}
function generateStructure(_a) {
    var requireReadable = _a.requireReadable, requireWritable = _a.requireWritable;
    return __awaiter(this, void 0, void 0, function () {
        var culturalInstitutions, structures, branchOffices, branchOfficeIds, _b, branchOffices_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, jsonPost('/api/web-authenticated/cultural_institutions/get_all')];
                case 1:
                    culturalInstitutions = _c.sent();
                    structures = [];
                    return [4 /*yield*/, (requireWritable ? getMyBranchOffices() : getMyBranchOfficesIncludingChildren())];
                case 2:
                    branchOffices = _c.sent();
                    branchOfficeIds = branchOffices.map(function (b) { return b.id; });
                    structures.push({
                        key: 'mine',
                        label: 'Mine DKS-enheter',
                        children: generateStructure_recursive(branchOffices, culturalInstitutions.filter(function (c) { return intersection(c.branchOfficeIds, branchOfficeIds).length > 0; }), null)
                    });
                    _b = !(requireReadable || requireWritable);
                    if (_b) return [3 /*break*/, 4];
                    return [4 /*yield*/, isSystemAdministrator()];
                case 3:
                    _b = (_c.sent());
                    _c.label = 4;
                case 4:
                    if (!_b) return [3 /*break*/, 6];
                    return [4 /*yield*/, getBranchOffices()];
                case 5:
                    branchOffices_1 = _c.sent();
                    structures.push({
                        key: 'all',
                        label: 'Alle DKS-enheter',
                        children: generateStructure_recursive(branchOffices_1, culturalInstitutions, null)
                    });
                    _c.label = 6;
                case 6: return [2 /*return*/, structures];
            }
        });
    });
}
export default memo(function (_a) {
    var requireReadable = _a.requireReadable, requireWritable = _a.requireWritable, props = __rest(_a, ["requireReadable", "requireWritable"]);
    var _b = useState(null), structures = _b[0], setStructures = _b[1];
    useEffect(function () {
        generateStructure({ requireReadable: requireReadable, requireWritable: requireWritable })
            .then(setStructures);
    }, [requireReadable, requireWritable]);
    if (!structures)
        return React.createElement(LoadingModal, null);
    return React.createElement(HierarchySelectorDialog, __assign({ label: 'Velg kulturinstitusjoner', structures: structures }, props));
});
