import React, {memo, useEffect, useState} from "react";
import * as PropTypes from "prop-types";

const Helmet = React.lazy(() => import('react-helmet'));

let configuration = {};
const subscriptions = [];

const PageConfiguration = (props) => {
    const {title} = props;

    useEffect(() => {
        const newConfiguration = {...configuration, ...props};
        configuration = newConfiguration;
        subscriptions.forEach(s => s(newConfiguration))
    });

    return <React.Suspense fallback={null}>
        <Helmet>
            <title>{title}</title>
            <meta name={'page-title'} content={title}/>
        </Helmet>
    </React.Suspense>;
};

PageConfiguration.propTypes = {
    title: PropTypes.string.isRequired,
};

export default memo(PageConfiguration);

const withPageConfiguration = (Component) => {
    return props => {
        const configState = useState(configuration);

        useEffect(() => {
            const fn = c => configState[1](c);
            subscriptions.push(fn);
            configState[1](configuration);
            return () => subscriptions.splice(subscriptions.indexOf(fn), 1);
        }, []);

        return <Component {...props} {...configState[0]}/>;
    };
};

export {withPageConfiguration};