var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Icon, InputAdornment, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { createDataTableFilter } from "./utils";
var InputProps = {
    endAdornment: (React.createElement(InputAdornment, { position: "end" },
        React.createElement(Icon, null, "search"))),
};
var SearchField = React.memo(function (props) {
    var t = useTranslation('fields').t;
    return React.createElement(TextField, { autoFocus: true, fullWidth: true, id: props.id, label: props.label || t('search'), value: props.value || '', onChange: function (e) { return props.onChange(e.target.value); }, variant: "outlined", InputProps: InputProps, inputProps: __assign({}, (((props === null || props === void 0 ? void 0 : props.maxLength) || 0) > 0 ? { maxLength: props.maxLength } : {})) });
});
export default createDataTableFilter(SearchField, { key: "search", flex: 2 }, {});
