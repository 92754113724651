var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsonPost } from "../../utils/JsonApi";
import { getReadableBranchOfficeIds, getWritableBranchOfficeIds } from "../UserSession";
import { find } from "lodash-es";
var branchOfficesPromise = null;
var undeletedBranchOfficesPromise = null;
export function resetBranchOffices() {
    branchOfficesPromise = null;
    undeletedBranchOfficesPromise = null;
}
export function getBranchOffices() {
    if (!branchOfficesPromise) {
        branchOfficesPromise = jsonPost('/api/web-authenticated/branch_offices/get_branch_offices', { includeIsDeletedField: true });
    }
    return branchOfficesPromise;
}
export function getUndeletedBranchOffices() {
    if (undeletedBranchOfficesPromise) {
        return undeletedBranchOfficesPromise;
    }
    if (branchOfficesPromise) {
        undeletedBranchOfficesPromise = getBranchOffices().then(function (bs) { return bs.filter(function (b) { return !(b === null || b === void 0 ? void 0 : b.isDeleted); }); });
        return undeletedBranchOfficesPromise;
    }
    undeletedBranchOfficesPromise = jsonPost('/api/web-authenticated/branch_offices/get_branch_offices', { includeDeleted: false });
    return undeletedBranchOfficesPromise;
}
export function getBranchOfficeIds() {
    return getBranchOffices().then(function (b) { return b.map(function (b) { return b.id; }); });
}
export function getBranchOffice(id) {
    return getBranchOffices().then(function (b) { return find(b, function (b) { return b.id === id; }); });
}
export function getReadableBranchOffices() {
    return __awaiter(this, void 0, void 0, function () {
        var readableBranchOfficeIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getReadableBranchOfficeIds()];
                case 1:
                    readableBranchOfficeIds = _a.sent();
                    return [4 /*yield*/, getBranchOffices()];
                case 2: return [2 /*return*/, (_a.sent()).filter(function (b) { return readableBranchOfficeIds.includes(b.id); })];
            }
        });
    });
}
export function getWritableBranchOffices() {
    return __awaiter(this, void 0, void 0, function () {
        var writableBranchOfficeIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWritableBranchOfficeIds()];
                case 1:
                    writableBranchOfficeIds = _a.sent();
                    return [4 /*yield*/, getBranchOffices()];
                case 2: return [2 /*return*/, (_a.sent()).filter(function (b) { return writableBranchOfficeIds.includes(b.id); })];
            }
        });
    });
}
export function getUndeletedReadableBranchOffices() {
    return __awaiter(this, void 0, void 0, function () {
        var readableBranchOfficeIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getReadableBranchOfficeIds()];
                case 1:
                    readableBranchOfficeIds = _a.sent();
                    return [4 /*yield*/, getUndeletedBranchOffices()];
                case 2: return [2 /*return*/, (_a.sent()).filter(function (b) { return readableBranchOfficeIds.includes(b.id); })];
            }
        });
    });
}
export function getUndeletedWritableBranchOffices() {
    return __awaiter(this, void 0, void 0, function () {
        var writableBranchOfficeIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWritableBranchOfficeIds()];
                case 1:
                    writableBranchOfficeIds = _a.sent();
                    return [4 /*yield*/, getUndeletedBranchOffices()];
                case 2: return [2 /*return*/, (_a.sent()).filter(function (b) { return writableBranchOfficeIds.includes(b.id); })];
            }
        });
    });
}
export function getUndeletedWritableBranchOfficeIds() {
    return __awaiter(this, void 0, void 0, function () {
        var writableBranchOfficeIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWritableBranchOfficeIds()];
                case 1:
                    writableBranchOfficeIds = _a.sent();
                    return [4 /*yield*/, getUndeletedBranchOffices()];
                case 2: return [2 /*return*/, (_a.sent()).filter(function (b) { return writableBranchOfficeIds.includes(b.id); }).map(function (b) { return b.id; })];
            }
        });
    });
}
