var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var CachedResourceWrapper = /** @class */ (function () {
    function CachedResourceWrapper(refreshFunction, cacheStrategy) {
        var _this = this;
        this.networkValueSet = false;
        this.subscriptions = [];
        this.refresh = function () {
            var promise = _this.refreshFunction();
            _this.networkValuePromise = promise;
            promise.then(function (v) {
                _this.networkValue = v;
                _this.networkValueSet = true;
            });
            promise.then(function (v) { return _this.cacheStrategy.set(v); });
            promise.then(function (v) { return _this.subscriptions.forEach(function (fn) { return fn(v); }); });
            return promise;
        };
        this.refreshFunction = refreshFunction;
        this.cacheStrategy = cacheStrategy;
    }
    CachedResourceWrapper.prototype.networkFirst = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.networkOnly()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        e_1 = _a.sent();
                        // @ts-ignore
                        return [2 /*return*/, this.cacheOnly()];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CachedResourceWrapper.prototype.networkOnly = function () {
        return this.networkValuePromise || this.refresh();
    };
    CachedResourceWrapper.prototype.cacheOnly = function () {
        var _a;
        return (_a = this.networkValue) !== null && _a !== void 0 ? _a : this.cacheStrategy.get();
    };
    CachedResourceWrapper.prototype.setNetworkValue = function (value) {
        this.networkValueSet = true;
        this.networkValue = value;
        if (value instanceof Promise) {
            this.networkValuePromise = value;
        }
        else {
            this.networkValuePromise = new Promise(function (s) { return s(value); });
        }
    };
    CachedResourceWrapper.prototype.subscribe = function (fn) {
        var _this = this;
        this.subscriptions.push(fn);
        return function () { return _this.subscriptions.splice(_this.subscriptions.indexOf(fn), 1); };
    };
    return CachedResourceWrapper;
}());
export default CachedResourceWrapper;
var LocalStorageCacheStrategy = /** @class */ (function () {
    function LocalStorageCacheStrategy(name) {
        this.name = name;
    }
    LocalStorageCacheStrategy.prototype.has = function () {
        return localStorage.getItem(this.name) !== null;
    };
    LocalStorageCacheStrategy.prototype.get = function () {
        try {
            return JSON.parse(localStorage.getItem(this.name));
        }
        catch (e) {
            return null;
        }
    };
    LocalStorageCacheStrategy.prototype.set = function (value) {
        localStorage.setItem(this.name, JSON.stringify(value));
    };
    return LocalStorageCacheStrategy;
}());
export { LocalStorageCacheStrategy };
var NoCacheStrategy = /** @class */ (function () {
    function NoCacheStrategy() {
    }
    NoCacheStrategy.prototype.get = function () {
        return null;
    };
    NoCacheStrategy.prototype.has = function () {
        return false;
    };
    NoCacheStrategy.prototype.set = function (value) {
    };
    return NoCacheStrategy;
}());
export { NoCacheStrategy };
