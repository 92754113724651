var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
export function createDataTableFilter(field, filterOptions, fieldProps) {
    var Component = field;
    var wrappedComponent = function (props) {
        // @ts-ignore
        return React.createElement(Component, __assign({}, props, fieldProps));
    };
    return Object.assign(wrappedComponent, filterOptions);
}
