import React, {lazy} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import AdministratorPortalBasePage from "./AdministratorPortalBasePage";
import LoadingScreen from "../../components/loaders/LoadingScreen";
import isIe from "@braintree/browser-detection/is-ie";
import Ie11ErrorPage from "./pages/Ie11ErrorPage";
import {LanguageProvider} from "../performer_portal/PerformerPortal";
import {useUserSession} from "../../data/UserSession";
import {PortalContext} from "../../utils/portal";
import UserRoleListPage from "./pages/UserRoleListPage";
import AdministratorPortalThemeProvider from "../../utils/AdministratorPortalThemeProvider";
import { Language } from "../../data/constants";
const BranchOfficeListPage = lazy(() => import( "./pages/BranchOfficeListPage"));
const BranchOfficePage = lazy(() => import( "./pages/BranchOfficePage"));
const SchoolListPage = lazy(() => import( "./pages/SchoolListPage"));
const SchoolPage = lazy(() => import( "./pages/SchoolPage"));
const CulturalInstitutionListPage = lazy(() => import( "./pages/CulturalInstitutionListPage"));
const ProductionGroupListPage = lazy(() => import( "./pages/ProductionGroupListPage"));
const ProductionGroupPage = lazy(() => import( "./pages/ProductionGroupPage"));
const TourPage = lazy(() => import( "./pages/TourPage"));
const MessagingTemplatePage = lazy(() => import( "./pages/MessagingTemplatePage"));
const ProductionPage = lazy(() => import( "./pages/ProductionPage"));
const ApplicationPage = lazy(() => import( "./pages/ApplicationPage"));
const SystemSettingsPage = lazy(() => import( "./pages/SystemSettingsPage"));
const UserPage = lazy(() => import( "./pages/UserPage"));
const CulturalInstitutionPage = lazy(() => import( "./pages/CulturalInstitutionPage"));
const TourListPage = lazy(() => import( "./pages/TourListPage"));
const ProductionListPage = lazy(() => import( "./pages/ProductionListPage"));
const EventListPage = lazy(() => import( "./pages/EventListPage"));
const MessagesPage = lazy(() => import( "./pages/MessagesPage"));
const WordPressPage = lazy(() => import( "./pages/WordPressPage"));
const PerformerListPage = lazy(() => import( "./pages/PerformerListPage"));
const ApplicationListPageV2 = lazy(() => import( "./pages/ApplicationListPageV2"));
const FrontPage = lazy(() => import( "./pages/FrontPage"));
const BookingPage = lazy(() => import( "./pages/BookingPage"));
const AnnualReportListPage = lazy(() => import("./pages/AnnualReportListPage"));
const SignApplicationListPage = lazy(() => import("./pages/SignApplicationListPage"));

export default () => {
    const location = useLocation();
    const userSession = useUserSession();

    const isAdministrator = userSession.isSystemAdministrator;

    const page = <PortalContext.Provider value={"administrator"}>
        <AdministratorPortalBasePage>
            <React.Suspense fallback={<LoadingScreen/>}>
                <Switch>
                    <Route path={'/r/administrator/booking/:id/:page?'} component={BookingPage}/>
                    <Route path={'/r/administrator/wordpress/:id/:page?'} component={WordPressPage}/>
                    <Route path={'/r/administrator/messages'} component={MessagesPage}/>
                    <Route path={'/r/administrator/branch-offices'} component={BranchOfficeListPage}/>
                    <Route path={'/r/administrator/schools'} component={SchoolListPage}/>
                    <Route path={'/r/administrator/school/:id/:page?'} component={SchoolPage}/>
                    <Route path={'/r/administrator/branch-office/:id/:page?'} component={BranchOfficePage}/>
                    <Route path={'/r/administrator/cultural-institutions'} component={CulturalInstitutionListPage}/>
                    <Route path={'/r/administrator/cultural-institution/:id/:page?'} component={CulturalInstitutionPage}/>
                    <Route path={'/r/administrator/performers'} component={PerformerListPage}/>
                    <Route path={'/r/administrator/applications'} component={ApplicationListPageV2}/>
                    <Route path={'/r/administrator/sign-applications'} component={SignApplicationListPage}/>
                    <Route path={'/r/administrator/application/:id/:page?'} component={ApplicationPage}/>
                    <Route path={'/r/administrator/productions'} component={ProductionListPage}/>
                    <Route path={'/r/administrator/production/:id/:page?'} component={ProductionPage}/>
                    <Route path={'/r/administrator/user/:id/:page?'} component={UserPage}/>
                    <Route path={'/r/administrator/production-groups'} component={ProductionGroupListPage}/>
                    <Route path={'/r/administrator/production-group/:id/:page?'} component={ProductionGroupPage}/>
                    <Route path={'/r/administrator/events'} component={EventListPage}/>
                    <Route path={'/r/administrator/tours'} component={TourListPage}/>
                    {isAdministrator && <Route path={'/r/administrator/system-settings/:page?'} component={SystemSettingsPage}/>}
                    <Route path={'/r/administrator/tour/:id/:page?'} component={TourPage}/>
                    <Route path={'/r/administrator/messaging-template/:type/:language?'} component={MessagingTemplatePage}/>
                    <Route path={'/r/administrator/ie11'} component={Ie11ErrorPage}/>
                    <Route path={'/r/administrator/user-roles'} component={UserRoleListPage}/>
                    <Route path={'/r/administrator/annual-reports/:page?'} component={AnnualReportListPage}/>
                    <Route component={FrontPage}/>
                </Switch>
            </React.Suspense>
        </AdministratorPortalBasePage>
    </PortalContext.Provider>;

    if (isIe()) {
        return <Ie11ErrorPage/>;
    }
    const language = userSession.user.language ?? Language.NB;

    return <LanguageProvider language={language}>
        <AdministratorPortalThemeProvider>
            {page}
        </AdministratorPortalThemeProvider>
    </LanguageProvider>;
};
