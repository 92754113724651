import React, { memo, useState } from "react";
import { Button, } from "@material-ui/core";
import { useTypedTranslation } from "../../../utils/i18n";
import MobileDialog from "../../dialogs/MobileDialog";
import { isMobile } from "../../../utils/MobileUtils";
import IconText from "../../IconText";
export default memo(function (_a) {
    var style = _a.style, children = _a.children;
    var t = useTypedTranslation().t;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var isMobileView = isMobile();
    if (!isMobileView)
        return children;
    return React.createElement("div", { style: style },
        open && React.createElement(MobileDialog, { title: t('fields', 'filter'), onClose: function () { return setOpen(false); }, onSave: function () { return setOpen(false); } },
            React.createElement("div", { style: { margin: 16 } }, children)),
        React.createElement(Button, { color: "primary", variant: "outlined", style: {
                width: '100%',
            }, onClick: function () { return setOpen(true); } },
            React.createElement(IconText, { icon: 'filter_alt', text: t('fields', 'filter') })));
});
