import React from "react";
import * as PropTypes from "prop-types";
import _ from 'lodash'

const SCROLL_DEBOUNCE_DURATION = 300;

export default class InfiniteScrollableWrapper extends React.Component {
    static propTypes = {
        canLoadMore: PropTypes.bool.isRequired,
        onLoadMore: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    state = { loading: false }

    render() {
        const {children} = this.props;
        return <div
            style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%', overflowY: 'scroll'}}
            onScroll={this.onScroll}
        >
            {children}
        </div>;
    }

    onScroll = e => {
        const canLoadMore = this.props.canLoadMore;
        const loading = this.state.loading;

        if (!canLoadMore || loading)
            return;

        const pixelsShown = e.currentTarget.scrollTop + e.currentTarget.clientHeight;
        const pixelsTotal = e.currentTarget.scrollHeight;
        const shouldLoadMore = pixelsShown > (pixelsTotal * 0.9);
        
        if (shouldLoadMore)
            this.doLoadMore()
    }

    doLoadMore = _.debounce(async () => {
        const onLoadMore = this.props.onLoadMore
        
        this.setState({ loading: true }, async () => {
            await onLoadMore();

            this.setState({ loading: false });
        })

    }, SCROLL_DEBOUNCE_DURATION, {leading: false, trailing: true})
}