import React, {memo} from "react";
import PerformerPortalHeader from "./header/PerformerPortalHeader";
import {makeStyles} from "@material-ui/core/styles";
import JumpToContentLink from "../../widgets/JumpToContentLink";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        left: '0',
        top: '0',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch',
        width: '100%',
        height: '100%',
    },
    content: {
        position: 'relative',
        flex: '1',
    }
}, {name: 'PerformerPortalBasePage'});

const PerformerPortalBasePage = p => {
    const classes = useStyles();

    return <div className={classes.root}>
        <JumpToContentLink/>
        <PerformerPortalHeader/>
        <div role={"main"} id={"content"} className={classes.content}>
            {p.children}
        </div>
    </div>;
};

export default PerformerPortalBasePage;