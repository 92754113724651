import { Settings } from "luxon";
import UserSession from "../data/UserSession";
import areIntlLocalesSupported from 'intl-locales-supported';
export function configureLuxon() {
    var locale = 'nb';
    var session = UserSession.cacheOnly();
    if (session && session.user.language)
        locale = session.user.language;
    if (!areIntlLocalesSupported(locale)) {
        var IntlPolyfill = require('intl');
        require('intl/locale-data/jsonp/nb');
        require('intl/locale-data/jsonp/nn');
        require('intl/locale-data/jsonp/en');
        if (IntlPolyfill.__disableRegExpRestore) {
            IntlPolyfill.__disableRegExpRestore();
        }
        Intl.NumberFormat = IntlPolyfill.NumberFormat;
        Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
        require('date-time-format-timezone');
    }
    Settings.defaultLocale = locale;
    Settings.defaultZoneName = "Europe/Oslo";
}
