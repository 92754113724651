import React, {lazy, memo, Suspense} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {useUserSession} from "../data/UserSession";
import {isApp} from "../common/PWA";
import AuthenticationWindow from "./authentication/AuthenticationWindow";
import AdministratorPortal from "./administrator_portal/AdministratorPortal";
import PerformerPortal, {LanguageProvider} from "./performer_portal/PerformerPortal";
import CompleteProfileDialogLoader from "../widgets/complete_profile/CompleteProfileDialogLoader";
import isIE from "@braintree/browser-detection/is-ie";
import {makeStyles} from "@material-ui/core/styles";
import {Dialog, Paper, Typography} from "@material-ui/core";
import SchoolPortal from "./school_portal/SchoolPortal";
import LoadingScreen from "../components/loaders/LoadingScreen";
import PageConfiguration from "../utils/PageConfiguration";

const NewApplicationPage = lazy(() => import("./administrator_portal/pages/NewApplicationPage"));
const EditApplicationPage = lazy(() => import("./administrator_portal/pages/EditApplicationPage"));
const EditProductionPage = lazy(() => import("./administrator_portal/pages/EditProductionPage"));
const CloneProductionPage = lazy(() => import("./administrator_portal/pages/CloneProductionPage"));
const NewProductionPage = lazy(() => import("./administrator_portal/pages/NewProductionPage"));
const NewSimpleProductionPage = lazy(() => import("./administrator_portal/pages/NewSimpleProductionPage"));
const NewSimpleTourPage = lazy(() => import("./administrator_portal/pages/NewSimpleTourPage"));
const NewSimpleReportActivityPage = lazy(() => import("./administrator_portal/pages/NewSimpleReportActivityPage"));
const EditSimpleProductionPage = lazy(() => import("./administrator_portal/pages/EditSimpleProductionPage"));
const EditCurriculumPage = lazy(() => import("./administrator_portal/pages/EditCurriculumPage"));

export default memo(() => {
    if (isIE()) {
        return <InternetExplorerTemporarilyNotSupported/>;
    }

    const userSession = useUserSession();

    if (!userSession) {
        return <LanguageProvider>
            <PageConfiguration title={'Logg inn i DKS-portalen'}/>
            <AuthenticationWindow/>
        </LanguageProvider>;
    }

    const isAdminAvailable = !isApp() && userSession.availablePortals.includes('administrator');
    const isSchoolAvailable = !isApp() && userSession.availablePortals.includes('school');

    let defaultPortal = PerformerPortal;

    if (isSchoolAvailable)
        defaultPortal = SchoolPortal;

    if (isAdminAvailable)
        defaultPortal = AdministratorPortal;

    return <Suspense fallback={<LoadingScreen/>}>
        <BrowserRouter>
            <Switch>
                <Route path={'/r/administrator/applications/new'} component={NewApplicationPage}/>
                <Route path={'/r/administrator/application/:id/edit'} component={EditApplicationPage}/>
                <Route path={'/r/administrator/productions/new'} component={NewProductionPage}/>
                <Route path={'/r/administrator/production/:id/edit'} component={EditProductionPage}/>
                <Route path={'/r/administrator/production/:productionId/curriculum/edit'} component={EditCurriculumPage}/>
                <Route path={'/r/administrator/production/:id/application/:applicationId/clone'} component={CloneProductionPage}/>
                <Route path={'/r/administrator/annual-reports/reported_activity/:branchOfficeId/:applicationId?/new'} component={NewSimpleProductionPage}/>
                <Route path={'/r/administrator/annual-reports/reported_activity/:branchOfficeId/production/:productionId/edit'} component={EditSimpleProductionPage}/>
                <Route path={'/r/administrator/tours/new'} component={NewSimpleTourPage}/>
                <Route path={'/r/administrator/annual-reports/reported_activity/new/:targetBranchOfficeId?'} component={NewSimpleReportActivityPage}/>

                <Route path={'/r/performer/'} component={PerformerPortal}/>
                {isAdminAvailable && <Route path={'/r/administrator/'} component={AdministratorPortal}/>}
                {isSchoolAvailable && <Route path={'/r/school/'} component={SchoolPortal}/>}
                <Route component={defaultPortal}/>
            </Switch>

            <LanguageProvider>
                <CompleteProfileDialogLoader/>
            </LanguageProvider>
        </BrowserRouter>
    </Suspense>;
});


const useStyles = makeStyles({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexFlow: 'column',
        alignItems: 'stretch',
    },
    centerWrapper: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: '100%',
        maxWidth: '400px',
        padding: '20px'
    }
}, {name: 'InternetExplorerTemporarilyNotSupported'});

const InternetExplorerTemporarilyNotSupported = () => {
    const classes = useStyles();

    return <Dialog open fullScreen>
        <div className={classes.root}>
            <div className={classes.centerWrapper}>
                <Paper className={classes.paper}>
                    <Typography variant={"h4"} gutterBottom>Internet Explorer ikke støttet</Typography>
                    <Typography>Internet Explorer er midlertidig ikke støttet. Vennligst åpne denne siden
                        i en moderne nettleser som Chrome, Firefox eller Edge.</Typography>
                </Paper>
            </div>
        </div>
    </Dialog>;
};