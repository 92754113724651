import 'whatwg-fetch';
import { HttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "../../generated/fragmentTypes.json";
import { generate } from "randomstring";
import { onError } from "apollo-link-error";
import { renderModal } from "../utils/AnchorDiv";
import { NetworkAccessDeniedErrorHttp } from "../utils/JsonApi";
var fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionQueryResultData
});
var createClient = function () {
    var httpLink = new HttpLink({
        uri: "/graphql/",
        credentials: 'same-origin'
    });
    var errorLink = onError(function (_a) {
        var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
        if (graphQLErrors)
            graphQLErrors.forEach(function (_a) {
                var message = _a.message, locations = _a.locations, path = _a.path;
                return console.log("[GraphQL error]: Message: " + message + ", Location: " + locations + ", Path: " + path);
            });
        if (networkError) {
            renderModal(NetworkAccessDeniedErrorHttp);
        }
    });
    return new ApolloClient({
        cache: new InMemoryCache({ fragmentMatcher: fragmentMatcher }),
        link: ApolloLink.from([errorLink, httpLink]),
        defaultOptions: {
            query: {
                fetchPolicy: "no-cache"
            },
            mutate: {
                fetchPolicy: "no-cache"
            }
        }
    });
};
var createTestClient = function (email, roles) {
    var httpLink = new HttpLink({
        uri: 'http://localhost:8080/graphql/',
        headers: {
            authorization: 'IntegrationTestAuthenticator ' + email + ' ' + roles,
        }
    });
    return new ApolloClient({
        cache: new InMemoryCache({ fragmentMatcher: fragmentMatcher }),
        link: ApolloLink.from([httpLink]),
        defaultOptions: {
            query: {
                fetchPolicy: "no-cache"
            },
            mutate: {
                fetchPolicy: "no-cache"
            }
        }
    });
};
var createTestEmail = function (prefix) {
    return prefix + '-' + generate({ length: 12 }) + '@kulturtanken.test';
};
export default createClient;
export { createTestClient, createTestEmail };
