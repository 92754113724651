import React, {memo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link as MaterialLink, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        padding: '40px'
    }
}, {name: 'Ie11ErrorPage'});

export default memo(() => {
    const classes = useStyles();

    return <div className={classes.root}>
        <Typography variant={"h3"}>
            Internet Explorer 11 error
        </Typography>
        <Typography variant={"body1"}>
            Administratorportalen krever avansert funksjonalitet ikke tilgjengelig i Internet Explorer. Vennligst last
            ned Edge, Firefox eller Google Chrome for å benytte deg av administratorportalen.
        </Typography>

        <MaterialLink component={Link} to={'/r/performer'}>
            Gå til utøverportal
        </MaterialLink>
    </div>;
})