import React, {memo, useState} from "react";
import {Button, Checkbox, FormControlLabel, Popover} from "@material-ui/core";
import IconText from "../IconText";
import {useTranslation} from "react-i18next";

export default memo(props => {
    const {t} = useTranslation('fields');
    const value = props.value;
    const columns = props.columns;

    const [anchorEl, setAnchorEl] = useState(null);

    return <>
        <Button onClick={e => setAnchorEl(e.currentTarget)}>
            <IconText icon={'view_column'} text={t('visible_columns')}/>
        </Button>

        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transitionDuration={0}
        >
            <div style={{padding: '15px 20px'}}>
                {columns.map(({label, forExportOnly}, index) => !forExportOnly && <div key={index} style={{
                    margin: '-6px 0',
                    overflow: 'hidden'
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"primary"}
                                checked={value.includes(label)}
                                onChange={(e, checked) => {
                                    const columns = value.slice(0);
                                    if (checked) {
                                        if (!columns.includes(label))
                                            columns.push(label);
                                    } else {
                                        columns.splice(columns.indexOf(label), 1);
                                    }
                                    props.onChange(columns);
                                }}
                            />
                        }
                        label={label}
                    />
                </div>)}
            </div>
        </Popover>
    </>;
})