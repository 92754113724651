import React, {lazy, useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import PerformerPortalThemeProvider from "./PerformerPortalThemeProvider";
import PerformerPortalBasePage from "./PerformerPortalBasePage";
import LoadingScreen from "../../components/loaders/LoadingScreen";
import {useUserSession} from "../../data/UserSession";
import {I18nextProvider} from "react-i18next";
import {getI18n} from "../../utils/i18n";
import {PortalContext} from "../../utils/portal";
import ApplicationUnderConsiderationPage from "./pages/ApplicationUnderConsiderationPage";
import ExternalEvaluatorGroupPage from "./pages/ExternalEvaluatorGroupPage";
import { LanguageHTML, AttributeHTML } from "../../data/constants";

const MyApplicationsPage = lazy(() => import( "./pages/MyApplicationsPage"));
const CulturalInstitutionPage = lazy(() => import( "./pages/CulturalInstitutionPage"));
const MyToursPage = lazy(() => import( "./pages/MyToursPage"));
const MessagesPage = lazy(() => import( "./pages/MessagesPage"));
const ApplicationStatusPage = lazy(() => import( "./pages/ApplicationStatusPage"));
const ApplicationEditorPage = lazy(() => import( "./pages/ApplicationEditorPage"));
const ApplicationEvaluatorPage = lazy(() => import( "./pages/ApplicationEvaluatorPage"));
const MyProfilePage = lazy(() => import( "./pages/MyProfilePage"));
const TourItineraryPage = lazy(() => import( "./pages/TourItineraryPage"));
const TourWorkbenchPage = lazy(() => import( "./pages/TourWorkbenchPage"));

const page = <PortalContext.Provider value={"performer"}>
    <PerformerPortalThemeProvider>
        <PerformerPortalBasePage>
            <React.Suspense fallback={<LoadingScreen/>}>
                <Switch>
                    <Route path={'/r/performer/tour/:tourId/itinerary'} component={TourItineraryPage}/>
                    <Route path={'/r/performer/application/:id/status'} component={ApplicationStatusPage}/>
                    <Route path={'/r/performer/application/:id/editor'} component={ApplicationEditorPage}/>
                    <Route path={'/r/performer/application/:id/council-group/:councilGroupId/evaluator/:page?'} component={ApplicationEvaluatorPage}/>
                    <Route path={'/r/performer/cultural-institution/:id/:page?'} component={CulturalInstitutionPage}/>
                    <Route path={'/r/performer/my-tours'} component={MyToursPage}/>
                    <Route path={'/r/performer/messages'} component={MessagesPage}/>
                    <Route path={'/r/performer/applications'} component={MyApplicationsPage}/>
                    <Route path={'/r/performer/tour-workbench/:tourId/:version?'} component={TourWorkbenchPage}/>
                    <Route path={'/r/performer/external-evaluator-group'} component={ExternalEvaluatorGroupPage}/>
                    <Route path={'/r/performer/council-group/:councilGroupId/application-under-consideration'} component={ApplicationUnderConsiderationPage}/>
                    <Route component={MyProfilePage}/>
                </Switch>
            </React.Suspense>
        </PerformerPortalBasePage>
    </PerformerPortalThemeProvider>
</PortalContext.Provider>;

export default function PerformerPortal() {
    const userSession = useUserSession();

    return <LanguageProvider language={userSession.user.language}>
        {page}
    </LanguageProvider>;
}

export function LanguageProvider({language, children}) {
    const i18n = getI18n({lng: language});

    useEffect(() => {
        if (language && i18n.language !== language) {
            i18n.changeLanguage(language);
        }
        language && document.documentElement.setAttribute(AttributeHTML.LANG, LanguageHTML[language]);
        
    }, [language]);

    return <I18nextProvider i18n={i18n}>
        {children}
    </I18nextProvider>
}