import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AdministratorPortalHeader from "./header/AdministratorPortalHeader";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch',
        overflow: 'hidden',
    },
    content: {
        flex: '1',
        position: 'relative',
        overflow: 'hidden'
    }
}, {name: 'AdministratorPortalBasePage'});

const AdministratorPortalBasePage = p => {
    const classes = useStyles();

    return <div className={classes.root} id="administrator-portal-base-page-root">
        <AdministratorPortalHeader/>

        <div className={classes.content}>
            {p.children}
        </div>
    </div>;
};

export default AdministratorPortalBasePage;