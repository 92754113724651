import React, { memo, useEffect, useState } from "react";
import ScrollablePageStructure from "../../../components/page_structure/ScrollablePageStructure";
import { Typography } from "@material-ui/core";
import PageConfiguration from "../../../utils/PageConfiguration";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { styleWCAG } from "../../../utils/WCAGThemeUtils";
import ExternalEvaluatorGroupList from "../widgets/ExternalEvaluatorGroupList";
import { getExternalEvaluatorGroup } from '../../../data/repositories/ExternalEvaluatorGroupRepository';
var Title = withStyles(function (theme) {
    var _a;
    return ({
        root: (_a = {
                marginBottom: '24px',
                textAlign: 'center'
            },
            _a[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _a)
    });
})(Typography);
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        wrapper: (_a = {
                maxWidth: '940px',
                padding: '40px 20px',
                margin: '0 auto',
                width: '100%'
            },
            _a[theme.breakpoints.down('xs')] = {
                padding: '20px',
            },
            _a)
    });
});
export default memo(function () {
    var t = useTranslation('performer_portal').t;
    var classes = useStyles();
    var classesv2 = styleWCAG();
    var title = t('my_council_group');
    var _a = useState([]), councilGroups = _a[0], setCouncilGroups = _a[1];
    useEffect(function () {
        getExternalEvaluatorGroup().then(function (response) {
            setCouncilGroups(response);
        });
    }, []);
    return React.createElement(ScrollablePageStructure, null,
        React.createElement(PageConfiguration, { title: title }),
        React.createElement("div", { className: classes.wrapper },
            React.createElement(Title, { className: classesv2.title, variant: 'h2' }, title),
            React.createElement(ExternalEvaluatorGroupList, { councilGroups: councilGroups })));
});
