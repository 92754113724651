import { DateTime } from "luxon";
import { configureLuxon } from "../utils/LuxonUtils";
var DateTimeFormats = {
    DATE_MED: 'dd.MM.yyyy',
    DATE_FULL: 'cccc dd.MM.yyyy',
    DATETIME_MED: 'dd.MM.yyyy HH:mm',
    DATETIME_FULL: 'cccc dd.MM.yyyy HH:mm',
    TIME: 'HH:mm',
};
export default DateTimeFormats;
export function formatDateTimeMed(timestamp) {
    if (!timestamp)
        return null;
    return parseTime(timestamp).toFormat(DateTimeFormats.DATETIME_MED);
}
export function formatDateTimeFull(timestamp) {
    if (!timestamp)
        return null;
    return parseTime(timestamp).toFormat(DateTimeFormats.DATETIME_FULL);
}
export function formatDateMed(timestamp) {
    if (!timestamp)
        return null;
    return parseTime(timestamp).toFormat(DateTimeFormats.DATE_MED);
}
export function formatDateFull(timestamp) {
    if (!timestamp)
        return null;
    return parseTime(timestamp).toFormat(DateTimeFormats.DATE_FULL);
}
export function formatWeek(timestamp) {
    if (!timestamp)
        return null;
    return ("0" + parseTime(timestamp).weekNumber).substr(-2);
}
export function parseTime(timestamp) {
    configureLuxon();
    if (typeof timestamp === "string")
        return DateTime.fromISO(timestamp);
    return DateTime.fromSeconds(timestamp);
}
export function formatTime(timestamp) {
    if (!timestamp)
        return null;
    return parseTime(timestamp).toFormat(DateTimeFormats.TIME);
}
export function formatRelativeTime(timestamp) {
    var dateTime = parseTime(timestamp);
    var duration = dateTime.startOf("day").diffNow("days");
    switch (Math.ceil(duration.days)) {
        case 0:
            return 'I dag';
        case 1:
            return 'I morgen';
        default:
            return formatDateFull(timestamp);
    }
}
export function formatTimeRange(dateStart, dateEnd) {
    return formatTime(dateStart) + ' – ' + formatTime(dateEnd);
}
export function formatDateTimeRangeMed(dateStart, dateEnd) {
    return formatDateTimeMed(dateStart) + ' – ' + formatTime(dateEnd);
}
