import React from "react";
import * as PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import {CircularProgress} from "@material-ui/core";

const styles = {
    root: {
        overflowY: 'scroll',
        flex: '1',
        position: 'relative'
    },
    content: {
        padding: '8px 24px'
    }
};

const loader = <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px'}}>
    <CircularProgress size={25}/>
</div>;

const DataTableScrollContainer = React.memo(props => <div style={styles.root}>
    <InfiniteScroll
        pageStart={props.page}
        loadMore={props.loadMore}
        hasMore={props.hasMore}
        loader={loader}
        useWindow={false}
    >
        <div key={0} style={styles.content}>
            {props.children}
        </div>
    </InfiniteScroll>
</div>);

DataTableScrollContainer.propTypes = {
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
};

export default DataTableScrollContainer