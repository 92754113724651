import {Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

const StyledTable = withStyles({
    root: {
        filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.14)) drop-shadow(0px 2px 1px rgba(0,0,0,0.12)) drop-shadow(0px 1px 2px rgba(0,0,0,0.2))',
        background: '#FFF'
    }
})(Table);
const StyledTableHead = withStyles({})(TableHead);
const StyledTableFooter = withStyles({})(TableFooter);
const StyledTableBody = withStyles({})(TableBody);
const StyledTableRow = withStyles({
    root: {
        '&:hover': {
            background: '#EEE'
        },
        textDecoration: 'none'
    }
})(TableRow);
const StyledTableCell = withStyles({
    head: {
        background: '#e6ebfc',
        color: '#191919',
    },
    footer: {
        background: '#e6ebfc',
        color: '#191919',
    }
})(TableCell);

export {StyledTable, StyledTableHead, StyledTableFooter, StyledTableBody, StyledTableRow, StyledTableCell};