import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
var ProjectSelectorTabs = withStyles({
    root: {
        borderBottom: '1px solid #ddd',
        minHeight: '32px',
    }
})(Tabs);
var ProjectSelectorTab = withStyles({
    root: {
        minWidth: '0 !important',
        minHeight: '40px',
        marginRight: '0'
    },
    wrapper: {
        padding: '8px 16px !important',
    },
    selected: {
        color: '#2e83fe'
    }
})(Tab);
export { ProjectSelectorTabs, ProjectSelectorTab };
