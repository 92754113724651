import React, {memo, useCallback, useState} from "react";
import * as PropTypes from "prop-types";
import {AppBar, Button, Dialog, Icon, IconButton, Menu, MenuItem, Paper, Toolbar} from "@material-ui/core";
import AuthenticationWidget from "./AuthenticationWidget";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexFlow: 'column',
        alignItems: 'stretch',
    },
    centerWrapper: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        alignItems: 'stretch',
        flexFlow: 'column'
    }
}, {name: 'AuthenticationWindow'});

const AuthenticationWindow = React.memo(props => {
    const {onCancel} = props;
    const classes = useStyles();

    return <Dialog open fullScreen onClose={onCancel}>
        <div className={classes.root}>
            {onCancel ? <AppBar color={"default"} position={"static"}>
                <Toolbar>
                    <IconButton color={"inherit"} onClick={onCancel}>
                        <Icon>keyboard_arrow_left</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar> : null}

            <div className={classes.centerWrapper}>
                <div className={classes.content}>
                    <Paper className={classes.paper}>
                        <AuthenticationWidget {...props}/>
                    </Paper>
                    <ChangeLanguageToolbar/>
                </div>
            </div>
        </div>
    </Dialog>;
});

AuthenticationWindow.propTypes = {
    domain: PropTypes.string,
    onAuthenticated: PropTypes.func,
    onCancel: PropTypes.func,
};

export default AuthenticationWindow;


const ChangeLanguageToolbar = memo(() => {
    const {t, i18n} = useTranslation('languages');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = useCallback(e => setAnchorEl(e.currentTarget), [setAnchorEl]);
    const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

    return <div>
        <Button onClick={handleOpen}>
            {t(i18n.language)}
        </Button>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
            {['nb', 'nn', 'en'].map(language => <MenuItem
                key={language}
                selected={i18n.language === language}
                onClick={() => {
                    i18n.changeLanguage(language);
                    handleClose();
                }}
            >
                {t(language)}
            </MenuItem>)}
        </Menu>
    </div>
});