import React, {memo, useState} from "react";
import {AppBar, Badge, Box, Icon, IconButton, Toolbar, Typography, Popover} from "@material-ui/core";
import {Link} from "react-router-dom";
import UserHeaderButton from "../../administrator_portal/header/UserHeaderButton";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {withPageConfiguration} from "../../../utils/PageConfiguration";
import {useHeader} from "../../../data/repositories/HeaderRepository";
import {useJsonResource} from "../../../utils/JsonApi";
import LoadingScreen from "../../../components/loaders/LoadingScreen";
import {useTranslation} from "react-i18next";
import {Language} from "../../administrator_portal/pages/SystemSettingsPage.SchoolPortalView";
import { useTypedTranslation } from "../../../utils/i18n";
import { styleWCAG } from "../../../utils/WCAGThemeUtils"
import clsx from "clsx";
const PerformerPortalDrawer = React.lazy(() => import("./PerformerPortalHeaderDrawer"));

const useStyles = makeStyles(theme => ({
    appBar: {
        borderBottom: '1px solid #CCC',
    },
    toolbar: {
        height: '56px',
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    title: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        minWidth: 0
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        justifyContent: 'flex-end',

        [theme.breakpoints.down('xs')]: {
            flex: 'unset',
        }
    },
}));

export default withPageConfiguration(memo(({title}) => {
    const classes = useStyles();
    const classesv2 = styleWCAG();
    const [open, setOpen] = useState(false);
    const { t } = useTypedTranslation();


    return <AppBar position="static" color={"default"} elevation={0} className={classes.appBar} role={'navigation'}>
        <React.Suspense fallback={null}>
            <PerformerPortalDrawer
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />


            <Toolbar variant={"dense"} className={classes.toolbar}>
                <div className={classes.left}>
                    <IconButton color={'inherit'} onClick={() => setOpen(true)}>
                        <Icon aria-label={t('common', 'menu_icon')}>menu</Icon>
                    </IconButton>

                    <div style={{width: '15px'}}/>

                    {title && (
                        <Typography className={clsx(classes.title, classesv2.page_title)} color={"inherit"} variant="h1" >
                            {title}
                        </Typography>
                    )}
                </div>

                <Logo
                    component={Link}
                    to={'/r/performer'}
                />

                <div className={classes.right}>
                    <HelpButton portal={'performer'}/>
                    <MessageButton portal={'performer'}/>
                    <UserHeaderButton portal={'performer'}/>
                </div>
            </Toolbar>
        </React.Suspense>
    </AppBar>;
}));

const StyledBox = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))(Box);

const Logo = memo(props => <StyledBox {...props}>
    <img
        src={'/img/logo/icon-text.png'}
        alt={'Kulturtanken logo'}
        style={{height: '32px'}}
    />
</StyledBox>);


const MessageButton = memo(({portal}) => {
    const header = useHeader();
    const unreadCount = header && header.messaging ? header.messaging[portal]?.unreadCount : null;
    const { t } = useTypedTranslation();

    const icon = <Icon aria-label={t('common', 'notification_icon')}>notifications</Icon>;

    return <IconButton
        color={"inherit"}
        component={Link}
        to={'/r/' + portal + '/messages'}
    >
        {unreadCount
            ? <Badge color={"secondary"} badgeContent={unreadCount}>
                {icon}
            </Badge>
            : icon}
    </IconButton>;
});

const HelpButton = memo(({portal}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const header = useHeader();
    const response = header && header.supportInformation ? header.supportInformation[portal] : null;
    const {i18n} = useTranslation();

    return <>
        <IconButton
            color={"inherit"}
            component={"span"}
            onClick={e => setAnchorEl(e.currentTarget)}
            disabled={!response || !response[i18n.language]}
        >
            <Icon aria-label={i18n.t('help_icon')}>help</Icon>
        </IconButton>

        {response && response[i18n.language]
            ? <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} style={{minWidth: '300px'}}>
                    <Typography dangerouslySetInnerHTML={{__html: response[i18n.language]}}/>
                </Box>
            </Popover>
            : null}
    </>;
});

export {MessageButton, Logo, HelpButton};
