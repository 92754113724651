var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import isIe from "@braintree/browser-detection/is-ie";
var useStyles = makeStyles({
    // root: isIe() ? {
    //         display: 'flex',
    //         alignItems: 'stretch',
    //         flexFlow: 'column',
    //         ['& > *']: {
    //             marginBottom: '20px !important',
    //         }
    //     }
    //     : {
    //         display: 'grid',
    //         gridGap: '20px'
    //     },
    root: {
        display: 'grid',
        gridGap: '20px'
    },
    actions: {
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: 'max-content max-content max-content max-content max-content'
    }
}, { name: 'FormGrid' });
export default memo(function (_a) {
    var columns = _a.columns, className = _a.className, props = __rest(_a, ["columns", "className"]);
    var classes = useStyles();
    return React.createElement("div", __assign({ className: classes.root + ' ' + className, style: { gridTemplateColumns: columns ? 'repeat(' + columns + ', 1fr)' : undefined } }, props));
});
var ActionsRow = memo(function (props) {
    var classes = useStyles();
    return React.createElement("div", __assign({ className: classes.actions }, props));
});
export { ActionsRow };
