import React, {memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Checkbox, Link as MaterialLink, TableSortLabel, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {
    StyledTable,
    StyledTableBody,
    StyledTableCell,
    StyledTableFooter,
    StyledTableHead,
    StyledTableRow
} from "./Table";
import {difference, union} from "lodash-es";
import LoadingModal from "../loaders/LoadingModal";
import {red} from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const StickyTableCell = withStyles({
    head: {
        position: 'sticky',
        top: '0',
        border: '0',
        zIndex: '2'
    }
})(StyledTableCell);

const StyledCheckbox = withStyles({
    root: {
        padding: '0'
    }
})(Checkbox);

export default React.memo(props => {
    const {showFooter, rowLink, selectable, selectedIds, onToggleSelectedIds, onChangeSelectedIds, columns, data, rawData, sort, onSort, openInNewTab, lastOpenId, onRowClick, title, noResultsMsg} = props;
    const {t} = useTranslation();

    if (!data)
        return null;

    if (data.length === 0) {
        return <Typography>
            {noResultsMsg ?? title ? t("common:no_items_found", {list_label: title.toLowerCase().replace('dks', 'DKS').replace('tcs', 'TCS')}) : t("common:no_results")}
        </Typography>;
    }

    const visibleColumns = columns.filter(({label}) => props.visibleColumns.includes(label));

    return <StyledTable style={{width: '100%'}} component={'div'}>
        <StyledTableHead component={'div'}>
            <StyledTableRow component={'div'}>

                {selectable
                    ?
                    <StickyTableCell>
                        <SelectAllButton
                            data={rawData}
                            selectedIds={selectedIds}
                            onChangeSelectedIds={onChangeSelectedIds}
                        />
                    </StickyTableCell>
                    : null}

                {visibleColumns.map(({label, sortKey, labelProps, align, tooltip}, id) => <StickyTableCell
                    key={id}
                    component={'div'}
                    style={{textAlign: align}}
                    {...labelProps}
                >
                    {sortKey
                        ? <TableSortLabel
                            active={sort[0][0] === sortKey}
                            direction={sort[0][1].toLowerCase()}
                            onClick={() => {
                                if (sort[0][0] === sortKey)
                                    onSort([[sortKey, sort[0][1] === 'ASC' ? 'DESC' : 'ASC']]);
                                else
                                    onSort([[sortKey, 'ASC']]);
                            }}
                        >
                            {label}
                            {tooltip ? tooltip : null}
                        </TableSortLabel>
                        : label}
                </StickyTableCell>)}
            </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
            {(data || []).map(datum => <Row
                key={datum.id}
                selectable={selectable}
                selected={!!((selectedIds && selectedIds.includes(datum.id)))}
                onToggleSelectedIds={onToggleSelectedIds}
                datum={datum}
                visibleColumns={visibleColumns}
                rowLink={rowLink ? rowLink(datum) : null}
                openInNewTab={openInNewTab}
                onRowClick={onRowClick}
                isLastOpen={lastOpenId == datum.id}
            />)}
        </StyledTableBody>
        {showFooter
            ? <EnhancedTableFooter
                selectable={selectable}
                visibleColumns={visibleColumns}
                data={data}
            />
            : null}
    </StyledTable>;
});


function format(variable) {
    if (variable === true)
        return 'Ja';
    else if (variable === false)
        return 'Nei';
    else
        return variable;
}

const Row = React.memo(props => {
    const {rowLink, selectable, selected, datum, visibleColumns, openInNewTab, isLastOpen, onRowClick} = props;

    const deleted = datum.deleted || datum.anchorDeleted;

    return <StyledTableRow
        component={'div'}
        style={isLastOpen
            ? {background: '#EEE'}
            : (deleted
                ? {background: red[200]}
                : undefined)
        }
        onClick={() => onRowClick(datum.id)}
    >

        {selectable
            ?
            <StyledTableCell
                component={'div'}
                onClick={e => {
                    // e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <StyledCheckbox
                    checked={selected}
                    onChange={() => {
                        props.onToggleSelectedIds(datum.id);
                    }}
                />
            </StyledTableCell>
            : null}


        {visibleColumns.map(({variable, render, stringify, linkify, align}, id) => {
            if (render) {                
                const rowLinkProps = {
                    to: rowLink, 
                    target: openInNewTab ? "_blank": "_self", 
                    style: {
                        textAlign: align, 
                        textDecoration: 'none', 
                        color: '#000000de',
                        display: 'inline-block',
                        minHeight: '18px',
                        width: '100%'
                    }
                };

                return <React.Fragment key={id}>{render(datum, rowLinkProps)}</React.Fragment>;
            }
                

            let content = stringify ? stringify(datum) : format(datum[variable]);

            const link = linkify ? linkify(datum) : null;

            if (link) {
                if (Array.isArray(link)) {
                    const contents = content.split(',');
                    content = link.map((l, index) => {
                        return <MaterialLink href={l} target={"_blank"}>
                            {contents[index] + (index === link.length - 1 ? '' : ',')}
                        </MaterialLink>;
                    });
                } else {
                    content = <MaterialLink href={link} target={"_blank"}>
                        {content}
                    </MaterialLink>;
                }
            }

            return <StyledTableCell key={id} component={'div'} style={{textAlign: align, textDecoration: 'none'}} {...(rowLink ? {component: Link, to: rowLink, target: openInNewTab ? "_blank" : "_self"} : null)}>
                {content}
            </StyledTableCell>;
        })}
    </StyledTableRow>;
});

const EnhancedTableFooter = memo(({selectable, data, visibleColumns}) => {
    return <StyledTableFooter>
        <StyledTableRow>
            {selectable
                ? <StyledTableCell/>
                : null}

            {visibleColumns.map(({footerStringify}, index) => {
                if (footerStringify) {
                    return <StyledTableCell key={index}>
                        {footerStringify(data)}
                    </StyledTableCell>;
                } else {
                    return <StyledTableCell key={index}/>;
                }
            })}
        </StyledTableRow>
    </StyledTableFooter>;
});

const SelectAllButton = memo(({data, selectedIds, onChangeSelectedIds}) => {
    let ids = null;

    if (data) {
        ids = data.ids;

        if (!data.hasMore) {
            ids = data.rows.map(r => r.id);
        }
    }

    const [selecting, setSelecting] = useState(false);

    const handleChange = (e, checked) => {
        if (checked) {
            if (ids) {
                onChangeSelectedIds(union(ids, selectedIds));
                setSelecting(true);
            } else if (!selectedIds) {
                onChangeSelectedIds([]);
                setSelecting(true);
            }
        } else {
            onChangeSelectedIds([]);
        }
    };

    useEffect(() => {
        if (selecting && Array.isArray(ids)) {
            setSelecting(false);
            onChangeSelectedIds(union(ids, selectedIds));
        }
    }, [selecting, ids, selectedIds]);

    return <>
        <StyledCheckbox
            checked={ids && difference(ids, selectedIds).length === 0}
            onChange={handleChange}
        />

        {selecting && !Array.isArray(ids) ? <LoadingModal/> : null}
    </>;
});