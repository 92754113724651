import React, { memo } from "react";
import { Badge, Icon } from "@material-ui/core";
export default memo(function (props) {
    return React.createElement(React.Fragment, null,
        React.createElement(Icon, { style: { marginRight: '10px' } }, props.icon),
        props.text);
});
var IconBadgeText = memo(function (props) {
    return React.createElement(React.Fragment, null,
        React.createElement(Badge, { badgeContent: props.badge, color: "secondary" },
            React.createElement(Icon, null, props.icon)),
        React.createElement("span", { style: { marginLeft: '10px' } }, props.text));
});
export { IconBadgeText };
